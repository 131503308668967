import { useEffect, useState } from "react";
import { Canvas, useThree } from "@react-three/fiber";
import {
  Environment,
  OrbitControls,
  MeshTransmissionMaterial,
  Html,
} from "@react-three/drei";
import { EffectComposer, Bloom } from "@react-three/postprocessing";
// import { useControls } from 'leva'
import { useSocket } from "../services/StateProviders";
import { useSpring, animated } from "@react-spring/three";

const AnimatedMeshTransmissionMaterial = animated(MeshTransmissionMaterial);

// Update the UserOrb component
const UserOrb = ({ user, position, scale }) => {
  const textColor = user.isTyping ? "white" : "var(--dark-green-color)";

  const springs = useSpring({
    color: user.isTyping
      ? "#46644a"
      : user.status === "online"
      ? "#efe2a4"
      : "#ffffff",
    textColor: user.isTyping ? "white" : "var(--dark-green-color)",
    config: { duration: 400 },
  });

  return (
    <group position={position}>
      <mesh castShadow receiveShadow scale={scale}>
        <sphereGeometry args={[1, 64, 64]} />
        <AnimatedMeshTransmissionMaterial
          resolution={1024}
          distortion={0.25}
          color={springs.color}
          thickness={1}
          anisotropy={1}
        />
      </mesh>
      <Html position={[0, 0, 0]} center distanceFactor={4.5} occlude={false}>
        {/* the problem here is the "color", used down below */}
        <div style={{ color: textColor }} className="orb-pointer">
          {user.username}
        </div>
      </Html>
    </group>
  );
};

const AIOrb = () => (
  <group>
    <mesh castShadow receiveShadow position={[1.75, 0.25, 1]} scale={1}>
      <sphereGeometry args={[1, 64, 64]} />
      <meshStandardMaterial color="#efe2a4" />
    </mesh>
  </group>
);

const Orbs = ({ users, userPositions, setUserPositions }) => {
  const { size, camera } = useThree();

  const ORB_SCALE = 0.8;
  const MIN_DISTANCE = ORB_SCALE * 2.5;
  const FOV = 25;

  const calculateFrameSize = () => {
    const vFOV = (FOV * Math.PI) / 180; // Convert vertical FOV to radians
    const height = 2 * Math.tan(vFOV / 2) * camera.position.z; // Visible height
    const aspectRatio = size.width / size.height;
    const width = height * aspectRatio;
    return { width: width / 2, height: height / 2 }; // Half sizes
  };

  const { width: FRAME_SIZE_X, height: FRAME_SIZE_Y } = calculateFrameSize();

  const generatePosition = () => [
    Math.random() * FRAME_SIZE_X * 2 - FRAME_SIZE_X,
    (Math.random() * FRAME_SIZE_Y * 1.8 - FRAME_SIZE_Y) * 0.8, // Slightly reduced vertical range
    Math.random() * FRAME_SIZE_Y * 2 - FRAME_SIZE_Y,
  ];

  const isPositionValid = (newPos, existingPositions) => {
    return !Object.values(existingPositions).some(
      (pos) =>
        pos &&
        Math.sqrt(
          Math.pow(pos[0] - newPos[0], 2) +
            Math.pow(pos[1] - newPos[1], 2) +
            Math.pow(pos[2] - newPos[2], 2)
        ) < MIN_DISTANCE
    );
  };

  useEffect(() => {
    const newPositions = { ...userPositions };

    users.forEach((user) => {
      if (!newPositions[user.userId]) {
        let newPosition;
        let attempts = 0;
        const maxAttempts = 50;

        do {
          newPosition = generatePosition();
          attempts++;
        } while (
          attempts < maxAttempts &&
          !isPositionValid(newPosition, newPositions)
        );

        newPositions[user.userId] = newPosition;
      }
    });

    setUserPositions(newPositions);
  }, [users.length]);

  return (
    <>
      {users.map(
        (user) =>
          userPositions[user.userId] && (
            <UserOrb
              key={user.userId}
              user={user}
              position={userPositions[user.userId]}
              scale={ORB_SCALE}
            />
          )
      )}
    </>
  );
};

export const Playground = ({ thinking = false, isConnected = true }) => {
  const { users } = useSocket();
  const [userPositions, setUserPositions] = useState({});

  return (
    <>
      <Canvas camera={{ position: [-5, 0.5, 5], fov: 45 }}>
        <color attach="background" args={["#fcf9f1"]} />
        <ambientLight intensity={0.5 * Math.PI} />
        <spotLight decay={0} position={[5, 5, -10]} angle={0.15} penumbra={1} />
        {/* <pointLight decay={0} position={[-10, -10, -10]} /> */}

        <Orbs
          users={users}
          userPositions={userPositions}
          setUserPositions={setUserPositions}
        />

        {/* <AIOrb /> */}

        <Environment files="https://dl.polyhaven.org/file/ph-assets/HDRIs/hdr/1k/aerodynamics_workshop_1k.hdr" />
        <OrbitControls
          makeDefault
          autoRotate
          autoRotateSpeed={0.1}
          minPolarAngle={0}
          maxPolarAngle={Math.PI / 2}
        />
        <EffectComposer>
          <Bloom luminanceThreshold={1} intensity={2} levels={9} mipmapBlur />
        </EffectComposer>
      </Canvas>
    </>
  );
};
