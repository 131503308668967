"use client";

import { WebSocketStatus } from "@hocuspocus/provider";
import { EditorContent, PureEditorContent } from "@tiptap/react";
import React, { useMemo, useRef } from "react";

import "../../styles/index.css";

import { Loader } from "../../components/ui/Loader";
import ImageBlockMenu from "../../extensions/ImageBlock/components/ImageBlockMenu";
// import { ColumnsMenu } from "@tiptap/extensions/MultiColumn/menus";
// import { TableColumnMenu, TableRowMenu } from "@tiptap/extensions/Table/menus";
import { createPortal } from "react-dom";
import { TiptapProps } from "./types";
import { EditorHeader } from "./components/EditorHeader";
import { TextMenu } from "../menus/TextMenu";
import { ContentItemMenu } from "../menus/ContentItemMenu";
import { useBlockEditor } from "../../hooks/useBlockEditor";
import { EditorContext } from "../../context/EditorContext";
import { LinkMenu } from "../menus/LinkMenu";
import { useAIState } from "../../hooks/useAIState";

import "cal-sans";

import "@fontsource/inter/100.css";
import "@fontsource/inter/200.css";
import "@fontsource/inter/300.css";
import "@fontsource/inter/400.css";
import "@fontsource/inter/500.css";
import "@fontsource/inter/600.css";
import "@fontsource/inter/700.css";

export const BlockEditor = ({ aiToken, ydoc, provider }: TiptapProps) => {
  const aiState = useAIState();
  const menuContainerRef = useRef(null);
  const editorRef = useRef<PureEditorContent | null>(null);

  const { editor, users, characterCount, collabState, leftSidebar } =
    useBlockEditor({ aiToken, ydoc, provider });

  const displayedUsers = users.slice(0, 3);

  const providerValue = useMemo(() => {
    return {
      isAiLoading: aiState.isAiLoading,
      aiError: aiState.aiError,
      setIsAiLoading: aiState.setIsAiLoading,
      setAiError: aiState.setAiError,
    };
  }, [aiState]);

  if (!editor) {
    return null;
  }

  const aiLoaderPortal = createPortal(
    <Loader label="thinking..." />,
    document.body
  );

  return (
    <EditorContext.Provider value={providerValue}>
      <div className="flex h-full" ref={menuContainerRef}>
        <div className="relative flex flex-col flex-1 h-full overflow-hidden">
          {/* <EditorHeader
            characters={characterCount.characters()}
            collabState={collabState}
            users={displayedUsers}
            words={characterCount.words()}
            isSidebarOpen={leftSidebar.isOpen}
            toggleSidebar={leftSidebar.toggle}
          /> */}
          <EditorContent
            editor={editor}
            // @ts-ignore
            ref={editorRef}
            className="flex-1 overflow-y-auto"
          />
          <ContentItemMenu editor={editor} />
          <LinkMenu editor={editor} appendTo={menuContainerRef} />
          <TextMenu editor={editor} />
          {/* <ColumnsMenu editor={editor} appendTo={menuContainerRef} /> */}
          {/* <TableRowMenu editor={editor} appendTo={menuContainerRef} />
          <TableColumnMenu editor={editor} appendTo={menuContainerRef} />*/}
          <ImageBlockMenu editor={editor} appendTo={menuContainerRef} />
        </div>
      </div>
      {aiState.isAiLoading && aiLoaderPortal}
    </EditorContext.Provider>
  );
};

export default BlockEditor;
