import { ChatCompletionMessageParam } from "openai/resources/index.js";

import {
  ChatCompletionChunk,
  ChatCompletionContentPart,
} from "openai/resources/chat/completions.js";
import { z } from "zod";
import { Client } from "socket.io/dist/client.js";
export type ratingTimestamp = {
  time: number;
  rating: number;
  comment?: string;
};

export type deltaTimestamp = {
  time: number;
  delta: number;
  comment?: string;
};

export type Ratings = {
  [userId: string]: {
    [solutionId: string]: ratingTimestamp[];
  };
};

export type SolutionRankings = { [solutionId: string]: number };

export type Summary = {
  summary: string;
  timeCreated: number;
  dataHash: string;
};

export type SummaryDiff = {
  diff: string;
  timeCreated: number;
  previousSummary: string;
  newSummary: string;
  dataHash: string;
};

export type Scratchpad = {
  scratchpad: string;
  timeCreated: number;
};

export type Conversations = {
  [userId: string]: ConversationItem[];
};

export type ExaReponse = {
  autopromptString?: string;
  results: ExaResult[];
  requestId?: string;
};

export type ExaResult = {
  title: string;
  url: string;
  text?: string;
  highlights?: string[];
  highlightScores?: number[];
  [key: string]: any;
};

export type AsyncTask = {
  id: string;
  completionTime: number;
  query: string;
  result: ExaReponse; // TODO allow more kinds of result types
  postProcessing?: {
    assistantName: string;
    assistantInstruction: string; // TODO clarify distinction between the result (what the query requires) and the assistant response (what the assistant responds to the query)
    assistantResponse: string;
  };
};

export type Scratchpads = {
  [userId: string]: Scratchpad;
};

export type Usage = {
  prompt_tokens: number;
  completion_tokens: number;
  total_tokens: number;
};

export type Transaction = {
  accountId: string;
  type: string; // "llm" | "web_search";    TODO
  model?: string;
  prompt_tokens?: number;
  completion_tokens?: number;
  total_tokens?: number;
  cost: number; // cost in hundreds of a cent
  time: number;
  comment: string;
};

export type FinancialRecords = {
  spend: number;
  //transactions: Transaction[];
};

export type Finances = {
  [userId: string]: FinancialRecords;
  shared: FinancialRecords;
};

export type Room = {
  phase: string;
  users: string[];
  id: string;
  name?: string;
  usernames: {
    [userId: string]: string;
  };
  conversations: Conversations;
  groupConversation: GroupConversationItem[];
  solutions: Solution[];
  solutionRankings: SolutionRankings;

  goal: string;
  files?: File[];
  creators?: Creator[];
  hashes: {
    [userId: string]: string;
  };
  summaries: { [userId: string]: Summary };
  scratchpads: Scratchpads;
  times: {
    created: number;
    lastMessage?: number;
    lastViewed?: number;
  };
  tasks?: AsyncTask[];
  notes?: string;
};

export type Tournament = {
  roomId: string;
  ratings: Ratings;
};

export type QueueItem = {
  solution1: Solution;
  solution2: Solution;
  userId: string;
};

export type Role = "system" | "user" | "assistant" | "function" | "tool";

export type OpenAIConversationItem = {
  role: Role;
  content: string;
};

export type GroupConversationItem = {
  role: Role;
  content: string;
  type: string;
  userId: string;
  timeAppended: number;
  id: string;
  solution?: Solution;
};

export type EventType =
  | "solution_suggestion"
  | "multiple_choice"
  | "offer_for_confirmation"
  | "highlight_solutions_sidebar"
  | "youtube_video"
  | "chat_message"
  | "search_web";
export type UserMessageType =
  | "solution_reaction"
  | "verdict_reaction"
  | "multiple_choice"
  | "normal";
export type AssistantMessageType = "..."; // TODO
export type MessageType =
  | EventType
  | "normal"
  | "solution_suggestion"
  | "offer_for_confirmation"
  | "match_played"
  | UserMessageType;

export type ConversationItemNotYetInDb = {
  role: Role;
  content: any; // TODO! string | Array<ChatCompletionContentPart>;
  type: MessageType;
  result?: any | undefined; // TODO
  name?: string;
  id?: string;
};

export type ConversationItem = ConversationItemNotYetInDb & {
  timeAppended: number;
};

export interface ExtraConversationMessages {
  [userId: string]: (ConversationItem | ChatCompletionMessageParam)[];
}

export interface EmbeddedExtraMessages {
  [userId: string]: (ConversationItem | ChatCompletionMessageParam)[];
}

export interface AppendedExtraMessages {
  [userId: string]: ChatCompletionMessageParam[];
}

export type ReturnReason = "normal" | "abandoned" | "interrupted" | "error";

export type FinishReason =
  | "stop_sequence" // anthropic
  | "end_turn"
  //
  | "STOP" // google
  //
  | "stop" // openai
  | "length"
  | "tool_calls"
  | "length"
  | "content_filter"
  | "function_call"
  | null;

export type SolutionFormat = "normal" | "minimal";

export type File = {
  name: string;
  path: string;
};

export const suggestedOption = z.object({
  title: z.string(),
  description: z.string(),
  hasImage: z.boolean(),
  image_search_query: z.string().optional().nullable(),
});

export type SuggestedOption = z.infer<typeof suggestedOption>;

export enum Vote {
  Up = "up",
  Neutral = "neutral",
  Down = "down",
}

export type Verdict = "fine" | "unknown" | "not really";

export type ClientAbbreviatedSolution = {
  // TODO unify with Solution somehow?
  id?: string;
  title: string;
  partial?: boolean;
  description?: string;
  hasImage?: boolean;
  image_search_query?: string;
  votes?: {
    [userId: string]: {
      vote: Vote;
    };
  };
  verdicts?: {
    [userId: string]: {
      verdict: Verdict;
    };
  };
  mentionedTo?: string[];
  thumbnailUrl?: string;
  originalUrl?: string;
  searchQuery?: string;
  origin?: string;
  sourceLink?: string;
  properties?: {
    key: string;
    val: string;
  }[];
};

export const real_option_name = "real_option";
export const imagined_option_name = "imagined_option";
export const sketch_name = "sketch";
export const meta_name = "meta";
export const other_name = "other";
export const TBD_name = "TBD";

export type SolutionType =
  | typeof real_option_name
  | typeof imagined_option_name
  | typeof sketch_name
  | typeof meta_name
  | typeof other_name
  | typeof TBD_name; // TODO: don't have a TBD type?
export const solution_types = `["${real_option_name}", "${imagined_option_name}", "${sketch_name}", "${meta_name}", "${other_name}", "${TBD_name}"]`;
export const filterFromTournament = [sketch_name, imagined_option_name];

export type Solution = SuggestedOption & {
  id: string;
  creatorId: string;
  votes: {
    [userId: string]: {
      vote: Vote;
    };
  };
  verdicts: {
    [userId: string]: {
      verdict: Verdict;
    };
  };
  mentionedTo: string[];
  thumbnailUrl: string;
  originalUrl: string;
  type: SolutionType;
  notes?: string;
  searchQuery?: string;
  origin?: string;
  sourceLink?: string;
  properties?: {
    key: string;
    val: string;
  }[];
  partial?: false;
};

export type ToolCallFunction = {
  name?: string;
  arguments?: string;
};

export type ExtendedToolCall = {
  id: string;
  index: number;
  arguments: string;
  name: string;
  function?: ToolCallFunction;
  streamableObject?: any; // Adjust the type according to the actual usage
};

// export type Delta = {
//   content?: string;
//   tool_calls?: ToolCall[];
// };

export type ToolDescription = {
  type: string;
  function: {
    name: string;
    description: string;
    parameters: any; // TODO: it's actually of type JSONSchema
    // parameters: {
    //   type: string,
    //   properties: {
    //     [key: string]: {
    //       type: string,
    //       description?: string,
    //       items?: {
    //         type: string,
    //       },
    //       enum?: string[],
    //     },
    //   },
    //   required: string[],
    // },
  };
};

export type Tag =
  | "event"
  | "solution_summary_data"
  | "conversation_meta"
  | "function_result"
  | "summary"
  | "interim_summary"
  | "chat_message"; // TODO make these variables imported from somewhere for consistency

export type User = {
  id: string;
  name?: string[];
  roomIds: string[];
  email?: string;
};

export type Sender = "gpt" | "user";
type ClientMessageType =
  | "multiple_choice"
  | "solution_suggestion"
  | "chat_message"; // TODO: unify this with EventType and the other function types

export type SolutionSuggestionResult = Solution[];
export type StreamSolutionSuggestionResult = (
  | Solution
  | ClientAbbreviatedSolution
)[];

export type HighlightUnseenSolutionsResult = {
  matchingSolutions: Solution[];
  newlyGenerated: string[];
};
export type MultipleChoiceResult = {
  allow_multiple_selections?: boolean;
  options: string[];
};

export type TargetChat = "group" | "ai";

export type MessageBase = {
  id: string;
  delta: any; // TODO
  completed: boolean;
  targetChat: TargetChat;
  sender: Sender;
};

export type SolutionSuggestionMessage = MessageBase & {
  result: SolutionSuggestionResult | StreamSolutionSuggestionResult;
  type: "solution_suggestion";
};

export type HighlightUnseenSolutionsMessage = MessageBase & {
  result: HighlightUnseenSolutionsResult;
  type: "highlight_unseen_solutions_from_sidebar";
};

export type MultipleChoiceMessage = MessageBase & {
  // TODO: this should not be called "ToolMessage", because it's no longer a tool proper
  result: MultipleChoiceResult;
  type: "multiple_choice";
};

export type YouTubeMessage = MessageBase & {
  result: any; // TODO
  type: "youtube_video";
};

export type SearchWebMessage = MessageBase & {
  result: any; // TODO
  type: "search_web";
};

export type ToolMessage =
  | SolutionSuggestionMessage
  | HighlightUnseenSolutionsMessage
  | MultipleChoiceMessage
  | YouTubeMessage
  | SearchWebMessage;

export type ChatAssistantMessage = MessageBase & {
  // TODO: these types are messy, clean up...
  content: string;
  sender: "gpt";
  userId: string;
  type: "chat_message";
  result?: any; // TODO
  solution?: any;
};

export type ChatUserMessage = MessageBase & {
  content: string;
  sender: "user";
  userId: string;
  result?: any;
  type: UserMessageType;
};

export type ClientMessage =
  | ToolMessage
  | ChatAssistantMessage
  | ChatUserMessage;

export interface TypingUsers {
  [userId: string]: {
    isTyping: boolean;
    chatId: string | null;
  };
}

export interface ClientUser {
  userId: string;
  username: string;
  status: string; // "online" | "offline";
  isTyping: boolean;
  typingIn: string | null;
}

export type Creator = {
  id: string;
  name?: string;
  algorithm?: (targetUserId: string, num_options: number) => string;
  description: string; // brief natural language description of what the point of the creator is, to be displayed on the frontend
  stats?: any; // for storing summary stats about how well this creators solutions perform
  solutions: any[];
};

export interface CreatorWithStats extends Creator {
  solutions: Solution[];
  stats: {
    bestSolutionScore: number;
    avgScore: number;
  };
}
export interface TopSolutionEmitData {
  solutions: Solution[];
  ratings: Ratings;
  rankings: SolutionRankings;
}
export type UserRoomMetadata = {
  roomId: string;
  name: string;
  created?: number;
  users: {
    userId: string;
    username: string;
  }[];
};

export type Score = {
  [userId: string]: number;
};

export const BrainstormedOptionSchema = z.object({
  cue: z.string().optional(),
  reasoning: z.string().optional(),
  title: z.string(),
  titles_babble: z
    .array(z.array(z.union([z.string(), z.record(z.number())])))
    .optional(),
  type_reasoning: z.string().optional(),
  type: z
    .enum([
      real_option_name,
      imagined_option_name,
      sketch_name,
      meta_name,
      other_name,
      TBD_name,
    ])
    .optional(),
  sourceLink: z.string().optional(),
});

export type BrainstormedOption = z.infer<typeof BrainstormedOptionSchema>;

export type UpdateSolutionMessage = {
  solutionId: string;
  newProperties: Partial<Solution>;
};

export type AddSolutionsMessage = {
  solutions: Solution[];
};

export type RankingsMessage = {
  rankings: SolutionRankings;
};

export type UpdateRankingsMessage = {
  rankingsDiff: SolutionRankings;
};

export const model_estimated_update = "Model estimated update";

export type UpdateTypingMessage = {
  userId: string;
  isTyping: boolean;
  typingIn: string | null;
};

export type ServerToClientApiMessageObjTypes = {
  "joined room": any;
  "room created": any;
  phase: any;
  "user rooms": any;
  "start message": any;
  "update clients": any;
  "update typing": UpdateTypingMessage;
  "suggestions refresh": any;
  solution_suggestion: any;
  search_web: any;
  youtube_video: any;
  messages: any;
  chat_message: any;
  "goal updated": any;
  matchData: any;
  finances: any;
  out_of_credits: any;
  brainstormQueueData: any;
  summary: any;
  error: any;
  "creator stats": any;
  offer_for_confirmation: any;
  multiple_choice: any;
  "thinking...": any;
  highlight_solutions_sidebar: any;
  updateSolution: any;
  "top solutions": any;
  addSolutions: any;
  rankings: any;
  updateRankings: any;
  "goal updated in db": any;
  "goal update error": any;
  everyone_fine_solution: { solution: Solution };
};
export type ServerToClientApiMessageType =
  keyof ServerToClientApiMessageObjTypes;

export const automatedMessagePrefix = "automated User message:";
