export const ACCOUNTING_UNIT = 10000; // 1 unit = 0.0001 USD
export const DEFAULT_SPEND_LIMIT = 2 * ACCOUNTING_UNIT; // 2 USD (TODO: set this on the server lol)
export const AI_NAME = "Chord";

// Collab Cloud configuration
// Your Tiptap Collab Cloud "App ID", see https://cloud.tiptap.dev/apps
export const REACT_APP_TIPTAP_COLLAB_APP_ID = "j9yp4z91";
export const TIPTAP_AI_APP_ID = "ykojvy6m";

export const TIPTAP_AI_BASE_URL =
  process.env.REACT_APP_TIPTAP_AI_BASE_URL || "https://api.tiptap.dev/v1/ai";
