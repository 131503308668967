import React from "react";
import { createRoot } from "react-dom/client";
import App from "./components/App";
import * as Sentry from "@sentry/react";

Sentry.init({
  environment: window.location.hostname.includes("7d39c655-1854")
    ? "development"
    : "production",
});

const container = document.getElementById("root");
const root = createRoot(container); // Create a root.

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
