import React, {
  useState,
  useEffect,
  memo,
  useMemo,
  useRef,
  Suspense,
  forwardRef,
  useLayoutEffect,
} from "react";
import { useParams } from "react-router-dom";
import {
  useSocket,
  useAppState,
  useCurrentUser,
  useSolutions,
} from "../services/StateProviders";
import "./NotesEditor/editorGlobals.css";

import "../styles/party.css";

import { useSpring, animated } from "react-spring";
import {
  InputUsernameModal,
  InviteModal,
  MessageForm,
  Messages,
  OutOfCreditsModal,
  TactileButton,
  TopSolutions,
} from "./Components";
import { ClientUser } from "../shared_types";
import { Playground } from "./Yolo";
import useWindowSize from "react-use/lib/useWindowSize";
import ReactModal from "react-modal";
import Confetti from "react-confetti";
import { useMediaQuery } from "react-responsive";
import { useSwipeable } from "react-swipeable";

import { TiptapCollabProvider } from "@hocuspocus/provider";
import { Rnd } from "react-rnd";
import { BlockEditor } from "./NotesEditor/components/BlockEditor";
import { Doc as YDoc } from "yjs";
import { REACT_APP_TIPTAP_COLLAB_APP_ID } from "../shared_flags";

export const Intro = () => {
  const { sendStartMessage, goal, handleGoalChange, justSolveIt, thinking } =
    useSocket();
  const [welcomeStartClicked, setWelcomeStartClicked] = useState(false);

  const handleStartButtonClick = () => {
    setWelcomeStartClicked(true);
  };

  const handleSubmitClick = () => {
    sendStartMessage();
  };

  const handleJustSolveItClick = () => {
    justSolveIt();
  };

  const goalIsAlmostEmpty = () => goal.trim().length < 7;

  return (
    <div id="welcome-message">
      {
        <>
          <div id="welcome-text">What are you coordinating?</div>
          <textarea
            id="goal-input-copy"
            autoComplete="on"
            className="input-box goal-input-prominent"
            value={goal}
            onChange={(e) => handleGoalChange(e.target.value, false)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleSubmitClick();
              }
            }}
            autoFocus
          ></textarea>
          <div id="party-start-container">
            <TactileButton
              className={"submit-goal-button"}
              onClick={handleSubmitClick}
              // disabled={goalIsAlmostEmpty()}
            >
              <div id="party-submit-text">Submit</div>
            </TactileButton>

            {/* <TactileButton
              className={"just-solve-it-button"}
              onClick={handleJustSolveItClick}
              // disabled={goalIsAlmostEmpty()}
            >
              Solve it ✨
            </TactileButton> */}
          </div>
        </>
      }
    </div>
  );
};

const getApiUrl = (endpoint: string): string => {
  const baseUrl =
    process.env.REACT_APP_ENVIRONMENT === "local"
      ? process.env.REACT_APP_BACKEND_URL
      : "";
  return `${baseUrl}/api/${endpoint}`;
};

const NewEditor = () => {
  const { roomId } = useSocket();
  const [provider, setProvider] = useState<TiptapCollabProvider | null>(null);
  const [collabToken, setCollabToken] = useState<string | null>(null);
  const [aiToken, setAiToken] = useState<string | null>(null);

  const hasCollab = true;

  useEffect(() => {
    // fetch data
    const dataFetch = async () => {
      const url = getApiUrl("collaboration");
      console.log("fetching collaboration token fromm", url);
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      });

      const data = await response.json();
      // @ts-ignore
      const { token } = data;

      // set state when the data received
      setCollabToken(token);
    };

    dataFetch();
  }, []);

  useEffect(() => {
    // fetch data
    const dataFetch = async () => {
      const url = getApiUrl("ai");
      const data = await (
        await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
        })
      ).json();

      const { token } = data;

      setAiToken(token);
    };

    dataFetch();
  }, []);

  const ydoc = useMemo(() => new YDoc(), []);

  useLayoutEffect(() => {
    if (hasCollab && collabToken) {
      setProvider(
        new TiptapCollabProvider({
          name: `${roomId}`,
          appId: REACT_APP_TIPTAP_COLLAB_APP_ID ?? "",
          token: collabToken,
          document: ydoc,
        })
      );
    }
  }, [setProvider, collabToken, ydoc, roomId, hasCollab]);

  if (hasCollab && (!collabToken || !provider)) return;

  return (
    <BlockEditor
      aiToken={aiToken}
      hasCollab={hasCollab}
      ydoc={ydoc}
      provider={provider}
    />
  );
};

interface NotesProps {
  isMinimized: boolean;
  toggleMinimize: () => void;
  controlPanelRef: React.RefObject<HTMLDivElement>;
  sidebarRef: React.RefObject<HTMLDivElement>;
}

const Notes: React.FC<NotesProps> = ({
  isMinimized,
  toggleMinimize,
  controlPanelRef,
  sidebarRef,
}) => {
  const [dimensions, setDimensions] = useState({
    width: "300px",
    height: "200px",
  });
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const { width: windowWidth, height: windowHeight } = useWindowSize();
  const isMobile = useMediaQuery({ maxWidth: 768 });

  useEffect(() => {
    const updateDimensions = () => {
      if (controlPanelRef.current) {
        const controlRect = controlPanelRef.current.getBoundingClientRect();

        const minimizedDimensions = {
          width: `${controlRect.width - 20}px`,
          height: "20px",
          x: controlRect.left + 10,
          y: controlRect.bottom,
        };

        let expandedDimensions;
        if (isMobile) {
          const width = Math.min(controlRect.width * 2, 300);
          const height = Math.min(windowHeight * 0.7, 550);
          expandedDimensions = {
            width: `${width}px`,
            height: `${height}px`,
            x: controlRect.left + (controlRect.width - width) / 2,
            y: controlRect.bottom + 20,
          };
        } else if (sidebarRef.current) {
          console.log("updating dimensions!");
          const sidebarRect = sidebarRef.current.getBoundingClientRect();
          const expandByX = 1.4;
          const expandByY = 0.65;
          expandedDimensions = {
            width: `${sidebarRect.width * expandByX}px`,
            height: `${windowHeight * expandByY}px`,
            x: windowWidth - sidebarRect.width * expandByX - 20, // 20px padding from right
            y: windowHeight - windowHeight * expandByY - 20, // 20px padding from bottom
          };
        } else {
          // Fallback dimensions if sidebar is not available on desktop
          expandedDimensions = {
            width: `${Math.min(windowWidth * 0.4, 600)}px`,
            height: `${windowHeight * 0.65}px`,
            x: windowWidth - Math.min(windowWidth * 0.4, 600) - 20,
            y: windowHeight - windowHeight * 0.65 - 20,
          };
        }

        if (isMinimized) {
          setDimensions({
            width: minimizedDimensions.width,
            height: minimizedDimensions.height,
          });
          setPosition({ x: minimizedDimensions.x, y: minimizedDimensions.y });
        } else {
          setDimensions({
            width: expandedDimensions.width,
            height: expandedDimensions.height,
          });
          setPosition({ x: expandedDimensions.x, y: expandedDimensions.y });
        }
      }
    };

    updateDimensions();
  }, [
    isMinimized,
    windowWidth,
    windowHeight,
    controlPanelRef,
    sidebarRef,
    isMobile,
  ]);

  return (
    <Rnd
      size={dimensions}
      position={position}
      onDragStop={(e, d) => setPosition({ x: d.x, y: d.y })}
      onResizeStop={(e, direction, ref, delta, position) => {
        setDimensions({
          width: ref.style.width,
          height: ref.style.height,
        });
        setPosition(position);
      }}
      className={`notes-window ${isMinimized ? "minimized" : ""}`}
      dragHandleClassName="notes-window-drag-handle"
      disableDragging={isMinimized}
    >
      <div className={`notes-header ${isMinimized ? "minimized" : ""}`}>
        <div className="notes-title-container">
          <div className="notes-header-title">📝 shared notes</div>
          <button className="minify-button" onClick={toggleMinimize}>
            {isMinimized ? (
              <i className="fa-solid fa-up-right-and-down-left-from-center"></i>
            ) : (
              <i className="fa-solid fa-down-left-and-up-right-to-center"></i>
            )}
          </button>
        </div>
        {!isMinimized && <div className="notes-window-drag-handle">⋮⋮⋮</div>}
      </div>
      <div
        className={`h-[105%] font-sans transition-opacity duration-300 ease-in-out ${
          isMinimized ? "opacity-30" : "opacity-100"
        }`}
        lang="en"
      >
        <div className="flex flex-col h-full">
          <div className="h-full">
            <NewEditor />
          </div>
        </div>
      </div>
    </Rnd>
  );
};

export const PartyChat = ({ chatId }) => {
  const { phase, joiningInProgress } = useSocket();
  const isStarted = () => phase !== "not_yet_started";

  return (
    <div id="party-chat-section">
      {!isStarted() && !joiningInProgress && <Intro />}
      {isStarted() && <Messages chatId={chatId} />}
      {isStarted() && <MessageForm chatId={chatId} />}
    </div>
  );
};

export const HelpModal = ({ isOpen, onRequestClose, style }) => {
  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      // parentSelector={() => document.querySelector('#root')}
      contentLabel="Invite Link Modal"
      ariaHideApp={false}
      style={style}
    >
      <div id="help-title">Chord is for Coordination.</div>
      <div id="help-text">
        Chord is an experimental AI tool to help groups find solutions everyone
        is excited about.
      </div>
      <div id="help-text">
        To use it: submit a goal, and then send the url of this page to anyone
        to invite them. Chord will handle the work of gathering preferences,
        suggesting solutions, and hashing out any disagreements.
      </div>
      <div id="help-text">
        {" "}
        More info{" "}
        <a
          href="https://x.com/jlagerros/status/1811242204352151812"
          style={{ color: "green" }}
        >
          here
        </a>{" "}
        (twitter announcement thread)
      </div>
    </ReactModal>
  );
};

const ControlPanel = forwardRef<
  HTMLDivElement,
  {
    users: ClientUser[];
    chatId: string;
    setChatId: any;
  }
>(({ users, chatId, setChatId }, ref) => {
  const { goal, handleGoalChange, phase, unread, setUnread, roomId } =
    useSocket();
  const [showModal, setShowModal] = useState(false);

  const numUsers = users.length;
  const notYetStarted = phase === "not_yet_started";

  const isMobile = useMediaQuery({ maxWidth: 768 });

  const showChatButtons = !notYetStarted && numUsers > 1;

  const handleClick = (chatId) => {
    setChatId(chatId);
    setUnread({ ...unread, [chatId]: 0 });
  };

  let content = {
    width: "400px",
    height: "100px",
    backgroundColor: "var(--lightest-primary)",
    opacity: 1,
    position: "absolute",
    left: "25%",
    top: "20%",
    borderRadius: "0px",
    border: "1px solid var(--dark-green-color)",
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    flexDirection: "column",
  };
  if (isMobile) {
    const mobileProps = {
      top: "13px",
      left: "0px",
      margin: "10px",
      width: "80%",
      height: "300px",
    };

    content = {
      ...content,
      ...mobileProps,
    };
  }

  const fadeIn = useSpring({
    opacity: showChatButtons ? 1 : 0,
    transform: showChatButtons ? "translateY(0px)" : "translateY(20px)",
    config: { tension: 300, friction: 20 },
  });
  return (
    <div className="control-panel" ref={ref}>
      <TactileButton
        className="invite-button"
        onClick={() => setShowModal(true)}
      >
        {numUsers > 1 ? "+ Invite" : "+ Invite friends"}
      </TactileButton>
      {/* <TactileButton
        className="notes-button"
        onClick={() => setShowModal(true)}
      >
        {numUsers > 1 ? "+ Invite" : "+ Invite friends"}
      </TactileButton> */}
      <InviteModal
        isOpen={showModal}
        onRequestClose={() => setShowModal(false)}
        style={{
          overlay: {
            // backgroundColor: "#000",
          },
          content,
        }}
      />
      <animated.div style={fadeIn}>
        {showChatButtons && (
          <TactileButton
            className={
              "chat-toggle-button" + (chatId === "ai" ? " pressed" : "") + " ai"
            }
            onClick={() => handleClick("ai")}
          >
            AI chat
            {unread.ai > 0 && (
              <div className="notification-count">{unread.ai}</div>
            )}
          </TactileButton>
        )}
      </animated.div>
      <animated.div style={fadeIn}>
        {showChatButtons && (
          <TactileButton
            className={
              "chat-toggle-button" +
              (chatId === "group" ? " pressed" : "") +
              " group"
            }
            onClick={() => handleClick("group")}
          >
            Group chat
            {unread.group > 0 && (
              <div className="notification-count">{unread.group}</div>
            )}
          </TactileButton>
        )}
      </animated.div>
      {/* <animated.div style={fadeIn}>
        {showChatButtons && (
          <TactileButton
            className={
              "chat-toggle-button" +
              (chatId === "group" ? " pressed" : "") +
              " notes"
            }
            onClick={() => setShowNotes(true)}
          >
            Notes
          </TactileButton>
        )}
      </animated.div> */}

      <div className="octopus-container">
        <Playground />
      </div>
      <div className="mission-content">
        {/* <div className="mission-title">mission</div> */}
        {!notYetStarted && (
          <textarea
            className="editable-mission"
            value={goal}
            onChange={(e) => handleGoalChange(e.target.value, true)}
            placeholder=""
          />
        )}
      </div>
    </div>
  );
});

const PartyHeader = () => {
  return (
    <header className="party-header">
      <div id="logo">
        <a href="/">Chord</a>
      </div>
      {/* <UserInfo /> */}
    </header>
  );
};

const MobileSidebar = ({ isOpen, onClose, showHelpModal }) => {
  const sidebarHandlers = useSwipeable({
    onSwipedLeft: onClose,
    onSwipedRight: onClose,
  });

  return (
    <>
      {isOpen && <div className="sidebar-overlay" onClick={onClose} />}
      <div
        className={`mobile-sidebar ${isOpen ? "open" : ""}`}
        {...sidebarHandlers}
      >
        <div className="top-solutions-sidebar">
          {/* <div className="help" onClick={showHelpModal}>
            ?
          </div> */}
          {/* hotfix commenting out above for now */}
          <TopSolutions />
        </div>
      </div>
    </>
  );
};

const DesktopSidebar = forwardRef<
  HTMLDivElement,
  { showHelpModal: () => void }
>(({ showHelpModal }, ref) => (
  <div className="top-solutions-sidebar" ref={ref}>
    <div className="help" onClick={showHelpModal}>
      ?
    </div>
    <TopSolutions />
  </div>
));

export const PartyPage: React.FC = () => {
  const {
    users,
    rainConfetti,
    setRainConfetti,
    phase,
    chatId,
    setChatId,
    roomId,
  } = useSocket();
  const { width, height } = useWindowSize();

  const { userId } = useCurrentUser();
  const { setRoomId, errors, joiningInProgress } = useSocket();
  const { roomId: urlRoomId } = useParams();
  const [showModal, setShowModal] = useState(false);
  const showHelpModal = () => setShowModal(true);

  const [sidebarOpen, setSidebarOpen] = useState(false);
  const isMobile = useMediaQuery({ maxWidth: 768 });

  const [isNotesMinimized, setIsNotesMinimized] = useState(true);
  const controlPanelRef = useRef<HTMLDivElement>(null);
  const sidebarRef = useRef<HTMLDivElement>(null);

  const toggleMinimize = () => {
    setIsNotesMinimized(!isNotesMinimized);
  };

  useEffect(() => {
    setRoomId(urlRoomId);
  }, [urlRoomId, setRoomId]);

  const moreThanOneUser = users.length > 1;

  const isStarted = phase !== "not_yet_started";

  return (
    <div className="party-page-container">
      {rainConfetti && moreThanOneUser && (
        <div className="confetti">
          <Confetti
            recycle={false}
            width={width}
            height={height}
            numberOfPieces={700}
            colors={["#46644a", "#e4d476", "#efe2a4", "#fcf9f1", "green"]}
            onConfettiComplete={() => {
              setRainConfetti(false);
            }}
          />
        </div>
      )}

      <InputUsernameModal userId={userId} />
      <OutOfCreditsModal />
      <HelpModal
        isOpen={showModal}
        onRequestClose={() => setShowModal(false)}
        style={{
          overlay: {
            // backgroundColor: "#000",
          },
          content: {
            width: "450px",
            height: "210px",
            backgroundColor: "var(--lightest-primary)",
            opacity: 1,
            position: "absolute",
            left: "25%",
            top: "25%",
            borderRadius: "0px",
            border: "1px solid var(--dark-green-color)",
            boxShadow: "2px 2px 0 var(--dark-green-color)",
            justifyContent: "center",
            display: "flex",
            flexDirection: "column",
          },
        }}
      />

      <Suspense fallback={<div>soon...</div>}>
        <div className="party-page-content">
          <div className="left-column">
            <PartyHeader />
            {isMobile && isStarted && (
              <TactileButton
                className="sidebar-toggle"
                onClick={() => setSidebarOpen(true)}
              >
                📚
              </TactileButton>
            )}
            {roomId && isStarted && (
              <Notes
                isMinimized={isNotesMinimized}
                toggleMinimize={toggleMinimize}
                controlPanelRef={controlPanelRef}
                sidebarRef={sidebarRef}
              />
            )}
            <ControlPanel
              users={users}
              chatId={chatId}
              setChatId={setChatId}
              ref={controlPanelRef}
            />
            <PartyChat chatId={chatId} />
          </div>

          {isMobile ? (
            <MobileSidebar
              isOpen={sidebarOpen}
              onClose={() => setSidebarOpen(false)}
              showHelpModal={showHelpModal}
            />
          ) : (
            <DesktopSidebar showHelpModal={showHelpModal} ref={sidebarRef} />
          )}
        </div>
      </Suspense>
    </div>
  );
};
