import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  useAppState,
  useCurrentUser,
  useSocket,
  useSolutions,
} from "../services/StateProviders";
import ReactModal from "react-modal";
import throttle from "lodash/throttle";
import { AI_NAME } from "../shared_flags";
import {
  ClientUser,
  MessageType,
  Ratings,
  Solution,
  imagined_option_name,
  meta_name,
  model_estimated_update,
  real_option_name,
  sketch_name,
} from "../shared_types";
import React from "react";
import { Tooltip } from "react-tooltip";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip as ChartTooltip,
  ResponsiveContainer,
  Symbols,
} from "recharts";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { dark } from "react-syntax-highlighter/dist/esm/styles/prism";
import hljs from "highlight.js";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import rehypeRaw from "rehype-raw";
import { visit } from "unist-util-visit";
import * as THREE from "three";
import { Canvas, useFrame, useThree } from "@react-three/fiber";
import qrcode from "qrcode";
import FlipMove from "react-flip-move";

interface TactileButtonProps {
  onClick: () => void;
  children: React.ReactNode;
  key?: string;
  className?: string;
  style?: "party" | "normal";
  disabled?: boolean;
}

export const TactileButton: React.FC<TactileButtonProps> = ({
  onClick,
  children,
  key = "",
  className = "",
  style = "party",
  disabled = false,
}) => {
  const [isPressed, setIsPressed] = useState(false);
  const [wasClicked, setWasClicked] = useState(false);

  useEffect(() => {
    if (wasClicked) {
      const timer = setTimeout(() => setWasClicked(false), 100);
      return () => clearTimeout(timer);
    }
  }, [wasClicked]);

  const handleMouseDown = () => setIsPressed(true);
  const handleMouseUp = () => setIsPressed(false);
  const handleClick = () => {
    setWasClicked(true);
    onClick();
  };

  return (
    <button
      key={key}
      className={`tactile-button ${isPressed ? "pressed" : ""} ${
        wasClicked ? "clicked" : ""
      }  ${className} ${style}`}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      onMouseLeave={handleMouseUp}
      onClick={handleClick}
      disabled={disabled}
    >
      {children}
    </button>
  );
};

const Sphere = ({ thinking, isConnected, style = "light" }) => {
  const ref1 = useRef<THREE.Points>(null);
  const ref2 = useRef<THREE.Points>(null);
  const { scene } = useThree();

  useEffect(() => {
    // Create a canvas element to use as a texture
    const canvas = document.createElement("canvas");
    canvas.width = 64;
    canvas.height = 64;

    // Draw a gradient on the canvas
    const context = canvas.getContext("2d");
    const gradientColor = style === "light" ? "255, 255, 255" : "0, 0, 0";
    const gradient = context.createRadialGradient(32, 32, 0, 32, 32, 32);
    gradient.addColorStop(0, `rgba(${gradientColor}, 1)`);
    gradient.addColorStop(1, `rgba(${gradientColor}, 0)`);
    context.fillStyle = gradient;
    context.fillRect(0, 0, 64, 64);

    // Use the canvas as the texture
    const texture = new THREE.CanvasTexture(canvas);

    // Sphere setup
    const geometry = new THREE.IcosahedronGeometry(1, 6);
    const material = new THREE.PointsMaterial({
      size: 0.02,
      map: texture,
      transparent: true,
      blending: THREE.AdditiveBlending,
      depthWrite: false,
      vertexColors: true,
    });

    const colors = [];
    const baseColor = style === "light" ? 1 : 0;
    for (let i = 0; i < geometry.attributes.position.count; i++) {
      colors.push(baseColor, baseColor, baseColor);
    }
    geometry.setAttribute("color", new THREE.Float32BufferAttribute(colors, 3));

    ref1.current.geometry = geometry;
    ref1.current.material = material;
    const sphere = new THREE.Points(geometry, material);
    sphere.scale.set(2, 2, 2); // Scale the sphere by a factor of 2 on each axis

    ref2.current = sphere; // Assign the sphere to the ref
    scene.add(sphere); // Add the sphere to the scene

    // Cursor-following light setup
    const cursorLight = new THREE.PointLight(
      style === "light" ? 0xffffff : 0x000000,
      1.5,
      100
    );
    cursorLight.position.set(0, 0, 10); // Start position
    scene.add(cursorLight);

    return () => {
      // Cleanup
      scene.remove(sphere);
      scene.remove(cursorLight);
    };
  }, [scene, style]);

  const currentSpeedX = useRef(0.002);
  const currentSpeedY = useRef(0.004);
  const currentSpeedX2 = useRef(0.003);
  const currentSpeedY2 = useRef(0.006);

  const accelerationX = useRef(0);
  const accelerationY = useRef(0);
  const accelerationX2 = useRef(0);
  const accelerationY2 = useRef(0);

  useFrame((state, delta) => {
    delta = delta * 3; // Scale delta to speed up the simulation

    // Define target speeds based on 'thinking' state
    const targetSpeedX = thinking ? 0.05 : 0.002;
    const targetSpeedY = thinking ? 0.03 : 0.004;
    const targetSpeedX2 = 0.003; // Assuming this remains constant for simplicity
    const targetSpeedY2 = thinking ? 0.006 : 0.006;

    // Define acceleration towards the target speed
    // The difference between target speed and current speed determines the acceleration
    // Adjust the multiplier for acceleration to control how quickly it responds
    accelerationX.current +=
      (targetSpeedX - currentSpeedX.current) * delta * 0.5;
    accelerationY.current +=
      (targetSpeedY - currentSpeedY.current) * delta * 0.5;
    accelerationX2.current +=
      (targetSpeedX2 - currentSpeedX2.current) * delta * 0.5;
    accelerationY2.current +=
      (targetSpeedY2 - currentSpeedY2.current) * delta * 0.5;

    // Update current speed by adding acceleration
    currentSpeedX.current += accelerationX.current * delta;
    currentSpeedY.current += accelerationY.current * delta;
    currentSpeedX2.current += accelerationX2.current * delta;
    currentSpeedY2.current += accelerationY2.current * delta;

    // Apply damping to simulate friction and air resistance, making the object gradually slow down
    // Damping factor should be close to but less than 1. Adjust for desired 'weight' feel
    const damping = 0.98;
    accelerationX.current *= damping;
    accelerationY.current *= damping;
    accelerationX2.current *= damping;
    accelerationY2.current *= damping;

    if (isConnected) {
      // Apply current speed to rotation
      if (ref1?.current) {
        ref1.current.rotation.x += currentSpeedX.current;
        ref1.current.rotation.y += currentSpeedY.current;
      }
      if (ref2?.current) {
        ref2.current.rotation.x += currentSpeedX2.current;
        ref2.current.rotation.y += currentSpeedY2.current;
      }
    }

    // Update vertex colors based on state and style
    const colors = ref1.current.geometry.attributes.color.array;
    const baseColor = style === "light" ? 1 : 0;
    for (let i = 0; i < colors.length; i += 3) {
      colors[i] = baseColor + (Math.random() - 0.5) * 0.1; // Red
      colors[i + 1] = baseColor + (Math.random() - 0.5) * 0.1; // Green
      colors[i + 2] = baseColor + (Math.random() - 0.5) * 0.1; // Blue
    }
    ref1.current.geometry.attributes.color.needsUpdate = true;
  });

  return <points ref={ref1} />;
};

const IndieSphere = ({ thinking, isConnected, style = "light" }) => {
  const ref1 = useRef<THREE.Points>(null);
  const ref2 = useRef<THREE.Points>(null);
  const { scene } = useThree();

  useEffect(() => {
    // Create a canvas element to use as a texture with a hand-drawn effect
    const canvas = document.createElement("canvas");
    canvas.width = 64;
    canvas.height = 64;

    const context = canvas.getContext("2d");
    context.fillStyle = style === "light" ? "#FFFBCE" : "#000000";
    context.fillRect(0, 0, 128, 128);

    context.fillRect(0, 0, 64, 64);

    // Add hand-drawn crosshatching effect
    context.strokeStyle = style === "light" ? "#000" : "#fff";
    context.lineWidth = 1;
    for (let i = 0; i < 64; i += 4) {
      context.beginPath();
      context.moveTo(i, 0);
      context.lineTo(0, i);
      context.stroke();
      context.beginPath();
      context.moveTo(64 - i, 64);
      context.lineTo(64, 64 - i);
      context.stroke();
    }

    const texture = new THREE.CanvasTexture(canvas);

    // Sphere setup
    const geometry = new THREE.IcosahedronGeometry(1, 6);
    const material = new THREE.PointsMaterial({
      size: 0.05,
      map: texture,
      transparent: true,
      blending: THREE.AdditiveBlending,
      depthWrite: false,
      vertexColors: true,
    });

    const colors = [];
    const baseColor = style === "light" ? 0.8 : 0.2; // Adjusted base color for better contrast
    for (let i = 0; i < geometry.attributes.position.count; i++) {
      colors.push(baseColor, baseColor, baseColor);
    }
    geometry.setAttribute("color", new THREE.Float32BufferAttribute(colors, 3));

    ref1.current.geometry = geometry;
    ref1.current.material = material;
    const sphere = new THREE.Points(geometry, material);
    sphere.scale.set(2, 2, 2); // Scale the sphere by a factor of 2 on each axis

    // Add bold outline
    const outlineMaterial = new THREE.MeshBasicMaterial({
      color: style === "light" ? 0x000000 : 0xffffff,
      side: THREE.BackSide,
    });
    const outlineGeometry = new THREE.IcosahedronGeometry(1.05, 6);
    const outlineMesh = new THREE.Mesh(outlineGeometry, outlineMaterial);
    sphere.add(outlineMesh);

    ref2.current = sphere; // Assign the sphere to the ref
    scene.add(sphere); // Add the sphere to the scene

    // Cursor-following light setup
    const cursorLight = new THREE.PointLight(
      style === "light" ? 0xffffff : 0x000000,
      1.5,
      100
    );
    cursorLight.position.set(0, 0, 10); // Start position
    scene.add(cursorLight);

    return () => {
      // Cleanup
      scene.remove(sphere);
      scene.remove(cursorLight);
    };
  }, [scene, style]);

  const currentSpeedX = useRef(0.002);
  const currentSpeedY = useRef(0.004);
  const currentSpeedX2 = useRef(0.003);
  const currentSpeedY2 = useRef(0.006);

  const accelerationX = useRef(0);
  const accelerationY = useRef(0);
  const accelerationX2 = useRef(0);
  const accelerationY2 = useRef(0);

  useFrame((state, delta) => {
    delta = delta * 3; // Scale delta to speed up the simulation

    // Define target speeds based on 'thinking' state
    const targetSpeedX = thinking ? 0.05 : 0.002;
    const targetSpeedY = thinking ? 0.03 : 0.004;
    const targetSpeedX2 = 0.003; // Assuming this remains constant for simplicity
    const targetSpeedY2 = thinking ? 0.006 : 0.006;

    // Define acceleration towards the target speed
    // The difference between target speed and current speed determines the acceleration
    // Adjust the multiplier for acceleration to control how quickly it responds
    accelerationX.current +=
      (targetSpeedX - currentSpeedX.current) * delta * 0.5;
    accelerationY.current +=
      (targetSpeedY - currentSpeedY.current) * delta * 0.5;
    accelerationX2.current +=
      (targetSpeedX2 - currentSpeedX2.current) * delta * 0.5;
    accelerationY2.current +=
      (targetSpeedY2 - currentSpeedY2.current) * delta * 0.5;

    // Update current speed by adding acceleration
    currentSpeedX.current += accelerationX.current * delta;
    currentSpeedY.current += accelerationY.current * delta;
    currentSpeedX2.current += accelerationX2.current * delta;
    currentSpeedY2.current += accelerationY2.current * delta;

    // Apply damping to simulate friction and air resistance, making the object gradually slow down
    // Damping factor should be close to but less than 1. Adjust for desired 'weight' feel
    const damping = 0.98;
    accelerationX.current *= damping;
    accelerationY.current *= damping;
    accelerationX2.current *= damping;
    accelerationY2.current *= damping;

    if (isConnected) {
      // Apply current speed to rotation
      if (ref1?.current) {
        ref1.current.rotation.x += currentSpeedX.current;
        ref1.current.rotation.y += currentSpeedY.current;
      }
      if (ref2?.current) {
        ref2.current.rotation.x += currentSpeedX2.current;
        ref2.current.rotation.y += currentSpeedY2.current;
      }
    }

    // Update vertex colors based on state and style
    const colors = ref1.current.geometry.attributes.color.array;
    const baseColor = style === "light" ? 0.8 : 0.2; // Adjusted base color for better contrast
    for (let i = 0; i < colors.length; i += 3) {
      colors[i] = baseColor + (Math.random() - 0.5) * 0.1; // Red
      colors[i + 1] = baseColor + (Math.random() - 0.5) * 0.1; // Green
      colors[i + 2] = baseColor + (Math.random() - 0.5) * 0.1; // Blue
    }
    ref1.current.geometry.attributes.color.needsUpdate = true;
  });

  return <points ref={ref1} />;
};

// ... existing code ...

export const InputUsernameModal = ({ userId }) => {
  const [usernameInput, setUsernameInput] = useState("");
  const usernameInputRef = useRef(null);

  const [emailInput, setEmailInput] = useState("");
  const emailInputRef = useRef(null);

  const { handleUsernameSubmit } = useSocket();

  // useEffect(() => {
  //   usernameInputRef.current.focus();
  // }, []);

  const handleUsernameChange = (e) => {
    setUsernameInput(e.target.value);
  };

  const handleEmailChange = (e) => {
    setEmailInput(e.target.value);
  };

  const handleSubmit = () => {
    handleUsernameSubmit(usernameInput, emailInput);
  };

  return (
    <ReactModal
      isOpen={!userId}
      // parentSelector={() => document.querySelector('#root')}
      contentLabel="Invite Link Modal"
      ariaHideApp={false}
      style={{
        overlay: {
          backgroundColor: "white",
        },
        content: {
          border: "none",
        },
      }}
    >
      <div className="modal">
        <div className="modal-content">
          <div id="welcome-title">Hello. Who are you?</div>

          <input
            className="modal-field-input"
            type="text"
            id="username-input"
            placeholder="name"
            onChange={handleUsernameChange}
            value={usernameInput}
            ref={usernameInputRef}
            required
            // onKeyDown={(e) => e.key === "Enter" && handleSubmit()}
          />

          <input
            className="modal-field-input"
            type="text"
            id="email-input"
            placeholder="email"
            onChange={handleEmailChange}
            value={emailInput}
            ref={emailInputRef}
            onKeyDown={(e) => e.key === "Enter" && handleSubmit()}
            required
          />
          <TactileButton
            className="submit-username"
            onClick={handleSubmit}
            disabled={!usernameInput || !emailInput}
          >
            Submit
          </TactileButton>
        </div>
      </div>
    </ReactModal>
  );
};

const CustomChartTooltip = ({
  active,
  payload,
  coordinate,
}: {
  active: boolean;
  payload: any[];
  coordinate: { x: number; y: number };
}) => {
  if (active && payload && payload.length) {
    const data = payload[0].payload;
    const commentParts = data.comment
      ? data.comment.split("Reasoning:")
      : ["", ""];

    return (
      <div
        className="custom-tooltip"
        style={{
          backgroundColor: "#fff",
          padding: "10px",
          border: "1px solid #ccc",
          position: "absolute",
          width: `400px`,
          left: `${coordinate.x}px`,
          top: `${coordinate.y + 20}px`, // Adjust this value to position the tooltip below the cursor
          transform: "translate(-50%, 0)", // Centers the tooltip horizontally
          pointerEvents: "none", // Ensures that the tooltip does not interfere with mouse events
          maxWidth: "300px", // Limits the width to ensure the tooltip is not too wide
          overflow: "auto", // Allows scrolling within the tooltip if content is too long
        }}
      >
        <p>
          <strong>User:</strong> {data.userId}
        </p>
        <p>
          <strong>Rating:</strong> {data.rating}
        </p>
        <p className="elo-chart-time">
          <strong>Time:</strong> {data.time}
        </p>
        <p>{commentParts[0].trim()}</p>
        {commentParts[1] && (
          <p>
            <strong>Reasoning:</strong> {commentParts[1].trim()}
          </p>
        )}
      </div>
    );
  }

  return null;
};

const EloChart = memo(
  ({
    ratings,
    allUsers,
    solution,
  }: {
    ratings: Ratings;
    allUsers: ClientUser[];
    solution: Solution;
  }) => {
    const getPointShape = (comment) => {
      if (comment) {
        if (comment.includes("Manually set to")) return "rect";
        if (comment.includes("Match.")) return "triangle";
        if (comment.includes(model_estimated_update)) return "star";
      }
      return "circle";
    };

    const createColorMapping = (userIds) => {
      const colorMapping = {};
      userIds.forEach((userId) => {
        let hash = 0;
        for (let i = 0; i < userId.length; i++) {
          hash = userId.charCodeAt(i) + ((hash << 5) - hash);
        }
        let color = "#";
        for (let i = 0; i < 3; i++) {
          const value = (hash >> (i * 8)) & 0xff;
          color += ("00" + value.toString(16)).substr(-2);
        }
        colorMapping[userId] = color;
      });
      return colorMapping;
    };

    const userIds = allUsers.map((u) => u.userId);
    const userColorMapping = createColorMapping(userIds);

    const chartData = useMemo(() => {
      if (!ratings) return [];

      return Object.entries(ratings).flatMap(([userId, userRatings]) => {
        const solutionRatings = userRatings[solution.id];
        if (!solutionRatings) return [];

        return solutionRatings.map((rating) => ({
          time: new Date(Number(rating.time)).toLocaleString(),
          rating: rating.rating,
          userId,
          color: userColorMapping[userId] || "gray",
          comment: rating.comment,
          pointShape: getPointShape(rating.comment),
        }));
      });
    }, [ratings, solution, userColorMapping]);

    const renderCustomizedDot = (props) => {
      const { cx, cy, stroke, payload } = props;
      let shape = payload.pointShape;
      return <Symbols cx={cx} cy={cy} size={50} fill={stroke} type={shape} />;
    };

    return (
      <ResponsiveContainer width="100%" height={400}>
        <LineChart
          data={chartData}
          margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="time" />
          <YAxis
            label={{ value: "Rating", angle: -90, position: "insideLeft" }}
            domain={[1100, 1300]}
          />
          {/* @ts-ignore TODO */}
          <ChartTooltip content={<CustomChartTooltip />} />
          {allUsers.map((user) => (
            <Line
              isAnimationActive={false}
              type="monotone"
              dataKey="rating"
              data={chartData.filter((d) => d.userId === user.userId)}
              stroke={userColorMapping[user.userId]}
              key={user.userId}
              dot={renderCustomizedDot}
            />
          ))}
        </LineChart>
      </ResponsiveContainer>
    );
  }
);

export const CustomTooltip = ({ id, content, children }) => {
  return (
    <>
      {React.cloneElement(children, {
        "data-tooltip-id": id,
        "data-tooltip-content": content,
      })}
      <Tooltip
        style={{
          width: "fit-content",
          zIndex: 1,
          fontSize: "var(--body-font-size)",
        }}
        id={id}
      />
    </>
  );
};

const iconMap = {
  TBD: { icon: "", tooltip: "To be determined" },
  other: { icon: "far fa-hand-holding-seedling", tooltip: "Other category" },
  imagined_option: { icon: "far fa-lightbulb", tooltip: "Imagined option" },
  [real_option_name]: { icon: "far fa-note-sticky", tooltip: "" },
  [sketch_name]: {
    icon: "far fa-seedling",
    tooltip: "Idea sketch (might be a hallucination)",
  },
  meta: {
    icon: "far fa-hand-holding-seedling",
    tooltip: "Outside the box idea",
  },
};

export const SolutionTypeIcon = ({
  type,
  id,
}: {
  type: string;
  id: string;
}) => {
  const { icon, tooltip } = iconMap[type] || iconMap["other"];

  return (
    <CustomTooltip id={id} content={tooltip}>
      <span className="solution-type-icon">
        <i className={icon} aria-hidden="true"></i>
      </span>
    </CustomTooltip>
  );
};

const AdminSolutionDetails = ({ solution }) => {
  return (
    <div
      style={{
        whiteSpace: "pre-wrap",
        overflowWrap: "break-word",
        fontSize: "10px",
      }}
    >
      <SyntaxHighlighter style={dark} language={"json"} PreTag="div">
        {String(JSON.stringify(solution, null, 2))}
      </SyntaxHighlighter>
    </div>
  );
};

const VoteButton = ({ direction, activeVote, solutionId }) => {
  const { userId } = useCurrentUser();
  const { setVote } = useSocket();
  const { sendSolutionReaction } = useSolutions();

  const icons = {
    up: "fa fa-thumbs-up",
    down: "fa fa-thumbs-down",
  };

  const handleVoteButtonClick = (vote) => {
    // optimistic response
    setVote({ userId, solutionId, vote });
    sendSolutionReaction({
      vote: activeVote === vote ? "neutral" : vote,
      solutionId,
      comment: "",
    });
  };

  return (
    <button
      className={`vote ${direction} ${
        activeVote === direction ? "active" : ""
      }`}
      onClick={() => handleVoteButtonClick(direction)}
    >
      <i className={icons[direction]} aria-hidden="true"></i>
    </button>
  );
};

const VerdictButton = ({
  verdict,
  activeVerdict,
  solutionId,
  triggerModelResponse = false,
}) => {
  const { userId } = useCurrentUser();
  const { sendVerdictReaction, setVerdict } = useSocket();

  const icons = {
    fine: "fa fa-check",
    discard: "fa fa-times",
  };

  const handleVerdictClick = async (verdict) => {
    // if (setVoteInteractionsCount) {
    //   setVoteInteractionsCount(voteInteractionsCount => voteInteractionsCount + 1);
    // }
    setVerdict({ userId, solutionId, verdict });
    sendVerdictReaction({
      verdict: activeVerdict === verdict ? "unknown" : verdict,
      solutionId,
      triggerModelResponse,
    });
  };

  return (
    <button
      className={`verdict ${verdict} ${
        activeVerdict === verdict ? "active" : ""
      }`}
      onClick={() => handleVerdictClick(verdict)}
    >
      <i className={icons[verdict]} aria-hidden="true"></i>
    </button>
  );
};

export const PreferencesContainer = ({
  solutionId,
  activeVote,
  activeVerdict,
  type,
}) => {
  return (
    <div className="preferences-container">
      {type === "solution_suggestion" && (
        <>
          <VerdictButton
            verdict="discard"
            activeVerdict={activeVerdict}
            solutionId={solutionId}
          />
          <VoteButton
            direction="down"
            activeVote={activeVote}
            solutionId={solutionId}
          />
          <VoteButton
            direction="up"
            activeVote={activeVote}
            solutionId={solutionId}
          />
        </>
      )}
      <VerdictButton
        verdict="fine"
        activeVerdict={activeVerdict}
        solutionId={solutionId}
      />

      {/* {type === "offer_for_confirmation" && (     TODO: restore this feature? 
        <button
          className={`not-yet verdict ${
            activeVerdict === "not really" ? "active" : ""
          }`}
          onClick={() => handleVerdictClick("not really")}
        >
          <span><i className="fa fa-times" aria-hidden="true"></i></span>
          <span className="not-really-text"> Not <br />really</span>
        </button>
      )} */}
    </div>
  );
};

const SolutionCard = memo(
  ({
    solution,
    userId,
    users,
    ratings,
    isAdmin,
  }: {
    solution: Solution;
    userId: string;
    users: ClientUser[];
    ratings: Ratings;
    isAdmin: boolean;
  }) => {
    const votes = solution.votes || {};
    const verdicts = solution.verdicts || {};

    return (
      <div
        className={`ranked-solution-card ${
          verdicts[userId]?.verdict === "fine" ? "solution-fine" : ""
        } ${
          // @ts-ignore
          verdicts[userId]?.verdict === "discard" ? "solution-discarded" : ""
        } ${solution.type}  
      `}
      >
        <SolutionTypeIcon
          type={solution.type}
          id={"card-type-icon" + solution.id + userId}
        />

        {!!solution?.sourceLink && (
          <a
            href={solution.sourceLink}
            target="_blank"
            rel="noopener noreferrer"
            className="source-link-container"
          >
            <i className="fa fa-link" aria-hidden="true"></i>{" "}
            {new URL(solution.sourceLink).hostname.replace(/^www\./, "")}
          </a>
        )}

        {solution.originalUrl && (
          <div className="top-solution-thumbnail thumbnail">
            <img src={solution.originalUrl} alt="Solution Thumbnail" />
          </div>
        )}

        <div className="ranked-solution-title">{solution.title}</div>
        <p className="ranked-solution-description">{solution.description}</p>

        {isAdmin && (
          <div>
            <EloChart ratings={ratings} allUsers={users} solution={solution} />
            <AdminSolutionDetails solution={solution} />
          </div>
        )}

        <PreferencesContainer
          solutionId={solution.id}
          activeVote={votes[userId]?.vote || "neutral"}
          activeVerdict={verdicts[userId]?.verdict || "unknown"}
          type="solution_suggestion"
        />
      </div>
    );
  }
);

export const SolutionCardPreview = ({ solution, isOpen, position }) => {
  const { users } = useSocket();
  const { ratings } = useSolutions();
  const isAdmin =
    new URLSearchParams(window.location.search).get("admin") !== null;
  return (
    <ReactModal
      isOpen={isOpen}
      contentLabel="Card preview modal"
      className={`ranked-solution-card preview`}
      overlayClassName="solution-card-preview-overlay"
      ariaHideApp={false}
      style={{
        content: {
          position: "absolute",
          left: position.left,
          top: position.top,
        },
      }}
    >
      <SolutionTypeIcon
        type={solution.type}
        id={"card-preview-type-icon" + solution.id}
      />

      {!!solution?.sourceLink && (
        <a
          href={solution.sourceLink}
          target="_blank"
          rel="noopener noreferrer"
          className="source-link-container"
        >
          <i className="fa fa-link" aria-hidden="true"></i>{" "}
          {new URL(solution.sourceLink).hostname.replace(/^www\./, "")}
        </a>
      )}

      {solution.originalUrl && (
        <div className="top-solution-thumbnail thumbnail">
          <img src={solution.originalUrl} alt="Solution Thumbnail" />
        </div>
      )}

      <div className="ranked-solution-title">{solution.title}</div>
      <p className="ranked-solution-description">{solution.description}</p>

      {isAdmin && (
        <div>
          <EloChart ratings={ratings} allUsers={users} solution={solution} />
          <AdminSolutionDetails solution={solution} />
        </div>
      )}
    </ReactModal>
  );
};

const SolutionListItem = React.memo(
  ({ solution, userId }: { solution: Solution; userId: string }) => {
    const votes = solution.votes || {};
    const verdicts = solution.verdicts || {};
    const [isHovered, setIsHovered] = useState(false);
    const [modalPosition, setModalPosition] = useState({
      left: "15%",
      top: "30%",
    });
    const listItemRef = useRef(null);

    const handleMouseEnter = () => {
      // setIsHovered(true); //m hotfix
      // if (listItemRef.current) {
      //   const rect = listItemRef.current.getBoundingClientRect();
      //   setModalPosition({
      //     left: `${rect.left - 350}px`,
      //     top: `${rect.top - 100}px`,
      //   });
      // }
    };

    return (
      <div
        ref={listItemRef}
        className={`solution-list-item 
          ${verdicts[userId]?.verdict === "fine" ? "fine" : ""}
          ${
            // @ts-ignore
            verdicts[userId]?.verdict === "discard" ? "discard" : ""
          }
          ${votes[userId]?.vote === "down" ? "down-vote" : ""}
          ${votes[userId]?.vote === "up" ? "up-vote" : ""}
          ${solution.type}`}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={() => setIsHovered(false)}
      >
        <SolutionTypeIcon
          type={solution.type}
          id={"type-icon" + solution.id + userId}
        />
        <div className="solution-list-item-title">{solution.title}</div>
        {isHovered && (
          <div className="list-item-preview">
            <SolutionCardPreview
              isOpen={isHovered}
              solution={solution}
              position={modalPosition}
            />
          </div>
        )}
        {/* TODO: fix the activeVote / activeVerdict stuff, a bit ugly */}

        <div className="list-item-vote-buttons">
          <VerdictButton
            verdict="discard"
            activeVerdict={verdicts[userId]?.verdict || "unknown"}
            solutionId={solution.id}
          />
          <VoteButton
            direction="down"
            activeVote={votes[userId]?.vote || "neutral"}
            solutionId={solution.id}
          />
          <VoteButton
            direction="up"
            activeVote={votes[userId]?.vote || "neutral"}
            solutionId={solution.id}
          />
          <VerdictButton
            verdict="fine"
            activeVerdict={verdicts[userId]?.verdict || "unknown"}
            solutionId={solution.id}
          />
        </div>
      </div>
    );
  }
);

const hideSolution = ({
  solution,
  ratings,
  filterSolutions,
}: {
  solution: Solution;
  ratings: Ratings;
  filterSolutions: string[];
}): boolean => {
  const userIds = Object.keys(ratings);

  // include solutions shown to users in conversation
  if (solution.origin === "top level" || solution.mentionedTo.length > 0) {
    return false;
  } else {
    // hide solutions that are bad for at least one person
    if (filterSolutions.includes("low_rated")) {
      for (const userId of userIds) {
        const userRatings = ratings?.[userId]?.[solution.id];
        if (userRatings) {
          const rating = userRatings.slice(-1)[0];
          if (rating && rating.rating < 1200) {
            return true;
          }
        }
      }
    }

    // hide background generated solutions with few and not very positive ratings
    if (filterSolutions.includes("unrated")) {
      for (const userId of userIds) {
        const userRatings = ratings?.[userId]?.[solution.id];
        if (userRatings) {
          const rating = userRatings.slice(-1)[0];
          if (userRatings.length < 10 && rating.rating <= 1400) {
            return true;
          }
        }
      }
    }
  }

  return false;
};

const FilterMenu = ({ filterSolutions, setFilterSolutions }) => {
  const [checkedState, setCheckedState] = useState({
    [real_option_name]: !filterSolutions.includes(real_option_name),
    [imagined_option_name]: !filterSolutions.includes(imagined_option_name),
    [sketch_name]: !filterSolutions.includes(sketch_name),
    [meta_name]: !filterSolutions.includes(meta_name),
    unrated: !filterSolutions.includes("unrated"),
    low_rated: !filterSolutions.includes("low_rated"),
  });

  const handleCheckboxChange = (type) => {
    const updatedCheckedState = {
      ...checkedState,
      [type]: !checkedState[type],
    };
    setCheckedState(updatedCheckedState);

    const newFilterSolutions = Object.keys(updatedCheckedState).filter(
      (key) => !updatedCheckedState[key]
    );
    setFilterSolutions(newFilterSolutions);
  };

  return (
    <div className="filter-menu">
      <label className="filter-menu-item">
        <input
          className="filter-checkbox"
          type="checkbox"
          checked={checkedState[real_option_name]}
          onChange={() => handleCheckboxChange(real_option_name)}
        />{" "}
        Real option
      </label>
      <label className="filter-menu-item">
        <input
          className="filter-checkbox"
          type="checkbox"
          checked={checkedState[imagined_option_name]}
          onChange={() => handleCheckboxChange(imagined_option_name)}
        />{" "}
        Imagined option
      </label>
      <label className="filter-menu-item">
        <input
          className="filter-checkbox"
          type="checkbox"
          checked={checkedState[sketch_name]}
          onChange={() => handleCheckboxChange(sketch_name)}
        />{" "}
        Sketch
      </label>
      <label className="filter-menu-item">
        <input
          className="filter-checkbox"
          type="checkbox"
          checked={checkedState[meta_name]}
          onChange={() => handleCheckboxChange(meta_name)}
        />{" "}
        Meta
      </label>
      <label className="filter-menu-item">
        <input
          className="filter-checkbox"
          type="checkbox"
          checked={checkedState["unrated"]}
          onChange={() => handleCheckboxChange("unrated")}
        />{" "}
        Unrated (AI)
      </label>
      <label className="filter-menu-item">
        <input
          className="filter-checkbox"
          type="checkbox"
          checked={checkedState["low_rated"]}
          onChange={() => handleCheckboxChange("low_rated")}
        />{" "}
        Low rated (AI)
      </label>
    </div>
  );
};

export const TopSolutions = () => {
  const { userId, isAdmin } = useCurrentUser();
  const { highlightSolutionsSidebar, users } = useSocket();
  const { solutions, ratings } = useSolutions();
  const { filterSolutions, setFilterSolutions } = useAppState();
  const [viewMode, setViewMode] = useState("list"); // 'list' or 'card'
  const [showFilterMenu, setShowFilterMenu] = useState(false);
  const toggleFilterMenu = () => setShowFilterMenu(!showFilterMenu);
  const [isHovering, setIsHovering] = useState(false);
  const [displayedSolutions, setDisplayedSolutions] = useState(solutions);

  // Effect to update displayed solutions when solutions change and user is not hovering
  useEffect(() => {
    if (!isHovering) {
      setDisplayedSolutions(solutions);
    }
  }, [solutions, isHovering]);

  const order = solutions.map((s) => s.id).join(",");

  const solutionCards = useMemo(() => {
    const dummy = order[0]; // just to get rid of a react warning

    return solutions
      .filter(
        (s) => !filterSolutions.includes(s.type) || s.mentionedTo?.length > 0
      )
      .filter(
        (solution) =>
          !hideSolution({ solution, ratings, filterSolutions }) ||
          solution.mentionedTo?.length > 0
      )
      .map((solution) => (
        <div key={solution.id}>
          <SolutionCard
            key={solution.id}
            solution={solution}
            userId={userId}
            users={users}
            ratings={ratings}
            isAdmin={isAdmin}
          />
        </div>
      ));
  }, [solutions, filterSolutions, ratings, userId, isAdmin, users, order]);

  const solutionListItems = useMemo(() => {
    const filteredSolutions = solutions
      .filter(
        (s) => !filterSolutions.includes(s.type) || s.mentionedTo?.length > 0
      )
      .filter(
        (solution) =>
          !hideSolution({ solution, ratings, filterSolutions }) ||
          solution.mentionedTo?.length > 0
      );

    const dummy = order[0]; // just to get rid of a react warning

    return filteredSolutions.map((solution, index) => (
      <div key={`${solution.id}`}>
        <SolutionListItem
          key={solution.id}
          solution={solution}
          userId={userId}
        />
      </div>
    ));
  }, [solutions, filterSolutions, ratings, userId, order]);

  const solutionView = viewMode === "card" ? solutionCards : solutionListItems;

  return (
    <>
      <div className={`right-sidebar`}>
        <div
          id="top-solutions"
          className={`${highlightSolutionsSidebar ? "glowing-animation" : ""}`}
          onMouseEnter={() => setIsHovering(true)}
          onMouseLeave={() => setIsHovering(false)}
        >
          {displayedSolutions.length > 0 && (
            <>
              <div className="view-mode-toggle">
                <button
                  onClick={() => setViewMode("card")}
                  className={`menu-row-button ${
                    viewMode === "card" ? "active" : ""
                  }`}
                >
                  {/* <i className="fa-solid fa-grid-2"></i> */}
                  <i
                    className="fa fa-grid-2"
                    id="grid-icon"
                    aria-hidden="true"
                  ></i>
                </button>
                <button
                  onClick={() => setViewMode("list")}
                  className={`menu-row-button ${
                    viewMode === "list" ? "active" : ""
                  }`}
                >
                  <i
                    className="fa fa-bars"
                    id="list-icon"
                    aria-hidden="true"
                  ></i>
                </button>

                {/* <span className="vertical-bar"></span> */}

                {/* <CustomTooltip
                  id="generate-solutions-tooltip"
                  content="Generate more solutions"
                >
                  <button
                    className="generate-solutions-button"
                    onClick={() => newSidebarSolutions()}
                    title="Generate more solutions"
                  >
                    ✨
                  </button>
                </CustomTooltip> */}

                {/* <span className="vertical-bar"></span>

                <CustomTooltip
                  id="filter-button-tooltip"
                  content="Filter solutions"
                >
                  <button
                    className="menu-row-button"
                    onClick={toggleFilterMenu}
                    title="Filter solutions"
                  >
                    <i className="fa fa-bars-filter" aria-hidden="true"></i>
                  </button>
                </CustomTooltip> */}
              </div>
              {showFilterMenu && (
                <FilterMenu
                  filterSolutions={filterSolutions}
                  setFilterSolutions={setFilterSolutions}
                />
              )}
            </>
          )}
          <FlipMove
            enterAnimation="fade"
            leaveAnimation="fade"
            className="top-solutions-cards-container"
            disableAllAnimations={false}
          >
            {solutionView}
            <div className="right-sidebar-bottom"></div>
          </FlipMove>
        </div>
      </div>
    </>
  );
};

const ThinkingAnimation = () => {
  const { thinking } = useSocket();
  return (
    <>
      {thinking && (
        <div id="thinking-animation">
          <div className="loader"></div>
        </div>
      )}
    </>
  );
};

const YoutubeVideo = ({ message }) => {
  const { result } = message;
  const link = useMemo(() => result?.link || [], [result]);

  const [videoId, setVideoId] = useState("");

  useEffect(() => {
    if (link) {
      try {
        const newVideoId = new URL(link).searchParams.get("v");
        if (newVideoId) {
          setVideoId(newVideoId);
        }
      } catch {} // sometimes the link is not a valid URL yet
    }
  }, [link]);

  return (
    videoId && (
      <div className={`message youtube-video-message`}>
        <iframe
          title="YouTube Video"
          src={`https://www.youtube.com/embed/${videoId}`}
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          style={{ width: "100%", height: "315px" }} // Standard height for YouTube embeds
        ></iframe>
      </div>
    )
  );
};

const ChatMessage = React.memo(({ message }: { message: any }) => {
  const { userId, isAdmin } = useCurrentUser();
  const { sender, content, userId: senderUserId } = message;
  const isOtherUserMessage = sender === "user" && senderUserId !== userId;
  const senderUsername = senderUserId ? senderUserId.slice(0, -13) : ""; // Assuming userId has a timestamp

  const preprocessedContent =
    sender === "gpt" ? preprocessContent(content) : content;

  const components = {
    sol: ({ node, ...props }) => {
      const jsonString = decodeURIComponent(node.properties["dataJson"]);
      let solution;
      try {
        solution = JSON.parse(jsonString);
      } catch (e) {
        console.error("Failed to parse solution JSON:", e);
        return null;
      }
      return <SolutionChip solution={solution} />;
    },
    thinking: ({ node, ...props }) => (
      <ThinkingBlock children={props.children} />
    ),
    function: ({ node, ...props }) => (
      <FunctionBlock children={props.children} />
    ),
    code({ node, inline, className, children, ...props }) {
      const match = /language-(\w+)/.exec(className || "");
      return !inline && match ? (
        <SyntaxHighlighter
          style={dark}
          language={match[1]}
          PreTag="div"
          {...props}
        >
          {String(children).replace(/\n$/, "")}
        </SyntaxHighlighter>
      ) : (
        <code className={className} {...props}>
          {children}
        </code>
      );
    },
  };

  return (
    <div
      className={`message ${isOtherUserMessage ? "other-user-message" : ""} ${
        sender === "gpt" ? "gpt-message" : "user-message"
      }`}
    >
      {isOtherUserMessage && <div className="sender">{senderUsername}</div>}
      <div>
        <ReactMarkdown
          children={preprocessedContent}
          remarkPlugins={[remarkGfm]}
          rehypePlugins={[rehypeRaw]}
          components={components}
        />
      </div>
    </div>
  );
});

const FunctionResultMessage = ({ message }) => {
  const { result } = message;
  const messageRef = useRef(null);
  const [isCollapsed, setIsCollapsed] = useState(true); // State to manage the collapse

  useEffect(() => {
    // Apply highlighting when the component mounts and result changes
    if (messageRef.current && !isCollapsed) {
      hljs.highlightBlock(messageRef.current);
    }
  }, [result, isCollapsed]); // Add isCollapsed as a dependency

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
    // Apply highlighting after state change if expanding
    if (isCollapsed && messageRef.current) {
      // Use setTimeout to ensure the DOM has updated
      setTimeout(() => hljs.highlightBlock(messageRef.current), 0);
    }
  };

  return (
    <div className={`message gpt-message ${isCollapsed ? "collapsed" : ""}`}>
      <button onClick={toggleCollapse} className="show-function-results">
        {isCollapsed ? "Show results" : "Hide results"}
      </button>
      {!isCollapsed && (
        <pre ref={messageRef}>
          <code className="json">{JSON.stringify(result, null, 2)}</code>
        </pre>
      )}
    </div>
  );
};

const MultipleChoice = React.memo(function MultipleChoice({
  message,
  isLastMessage,
}: {
  message: any;
  isLastMessage: boolean;
}) {
  const { sendMultipleChoiceReaction } = useSocket();
  const { id, result, completed } = message;

  const resultOptions = useMemo(() => result?.options || [], [result]);
  const resultSelectedOptions = useMemo(
    () => result?.selectedOptions || [],
    [result]
  );
  const allowMultipleSelections = useMemo(
    () => result?.options?.length > 0 && result?.allow_multiple_selections, // a hack for now to prevent "blinking" of choose-one-text
    [result]
  );
  const thousands = useMemo(
    () => Math.min(4000, Math.max(resultOptions.length * 500, 1500)),
    [resultOptions]
  );
  const countdownIntervalRef = useRef(null);
  const [timerStarted, setTimerStarted] = useState(false);
  const [timerFinished, setTimerFinished] = useState(false);
  const [eventSent, setEventSent] = useState(resultSelectedOptions.length > 0);
  const [selectedOptions, setSelectedOptions] = useState(resultSelectedOptions);
  const [timeLeft, setTimeLeft] = useState(0);

  const toggleItem = (item, array) => {
    if (array.includes(item)) {
      return array.filter((i) => i !== item);
    }
    return [...array, item];
  };

  useEffect(() => {
    const handleEnterKey = (event) => {
      if (completed && timerStarted) {
        if (event.key === "Enter") {
          event.preventDefault();
          setTimerFinished(true);
        }
      }
    };

    const handleHotkeyPress = (event) => {
      if (event.metaKey && !isNaN(event.key)) {
        const optionIndex = parseInt(event.key, 10) - 1;
        if (optionIndex >= 0 && optionIndex < resultOptions.length) {
          event.preventDefault();
          if (completed) {
            setSelectedOptions((prev) =>
              toggleItem(resultOptions[optionIndex], prev)
            );
            setTimerStarted(true);
            if (!allowMultipleSelections) {
              setTimerFinished(true);
            }
          }
        }
      }
    };

    if (isLastMessage) {
      document.addEventListener("keydown", handleEnterKey);
      document.addEventListener("keydown", handleHotkeyPress);
    }

    if (timerFinished) {
      document.removeEventListener("keydown", handleEnterKey);
      document.removeEventListener("keydown", handleHotkeyPress);
    }

    return () => {
      document.removeEventListener("keydown", handleEnterKey);
      document.removeEventListener("keydown", handleHotkeyPress);
    };
  }, [
    isLastMessage,
    completed,
    timerStarted,
    timerFinished,
    resultOptions,
    allowMultipleSelections,
  ]);

  const handleEnterClick = () => {
    if (completed && timerStarted && !timerFinished) {
      setTimerFinished(true);
    }
  };

  const handleOptionSelect = (option) => {
    if (completed && !timerFinished) {
      setSelectedOptions((prev) => toggleItem(option, prev));
      setTimerStarted(true);
      if (!allowMultipleSelections) {
        setTimerFinished(true);
      }
    }
  };

  useEffect(() => {
    if (timerStarted && !countdownIntervalRef.current && !timerFinished) {
      setTimeLeft(thousands);
      countdownIntervalRef.current = setInterval(() => {
        setTimeLeft((prevTimeLeft) => {
          const newTimeLeft = prevTimeLeft - 50;
          if (newTimeLeft <= 0) {
            clearInterval(countdownIntervalRef.current);
            setTimerFinished(true);
            return 0;
          }
          return newTimeLeft;
        });
      }, 50);
    }

    if (timerFinished && !eventSent && selectedOptions.length > 0) {
      sendMultipleChoiceReaction({
        ...result,
        multipleChoiceId: id,
        selectedOptions: selectedOptions,
        targetChat: "ai",
      });
      setEventSent(true);
    }
    // Add any other dependencies that are relevant and might change the outcome.
  }, [
    timerFinished,
    eventSent,
    selectedOptions,
    result,
    sendMultipleChoiceReaction,
    thousands,
    timerStarted,
    id,
  ]);

  const showHotkeys = isLastMessage && !timerFinished;

  return (
    <div className="multi-choice-container" id={`question-${message.id}`}>
      {isLastMessage &&
        !!completed &&
        !allowMultipleSelections &&
        !timerStarted && <div className="choose-one-text">Choose one</div>}

      <div className="multi-choice-buttons">
        {resultOptions.map((option, index) => (
          <div className="hotkey-wrapper">
            <TactileButton
              key={option}
              className={`option-button vote 
              ${selectedOptions.includes(option) ? "active pressed" : ""} 
              ${
                resultSelectedOptions.length > 0
                  ? resultSelectedOptions.includes(option)
                    ? "active-submitted pressed"
                    : "inactive-submitted"
                  : ""
              } 
              ${showHotkeys ? "hotkeys-visible" : ""}`}
              onClick={() => handleOptionSelect(option)}
            >
              <div className="option-text">{option}</div>
            </TactileButton>
            {showHotkeys && index <= 8 && (
              <div className={`hotkey-text`}>
                <span className="material-symbols-outlined cmd-icon">
                  keyboard_command_key
                </span>{" "}
                + {index + 1}
              </div>
            )}
          </div>
        ))}
      </div>
      {!timerStarted && isLastMessage && !!completed && (
        <div id="type-below-text">...or type below</div>
      )}
      {isLastMessage && timerStarted && !timerFinished && (
        <div className="time-left-container">
          <div
            className="time-left-bar"
            style={{ width: `${(timeLeft / thousands) * 100}%` }}
          ></div>
          <div className="enter-icon" onClick={handleEnterClick}>
            <div id="submit-text">submit</div>
            <span className="material-icons-outlined">keyboard</span>
            <span className="material-icons-outlined">keyboard_return</span>
          </div>
        </div>
      )}
    </div>
  );
});

const SolutionSuggestions = React.memo(
  ({
    message,
    isAlmostLastMessage,
  }: {
    message: any;
    isAlmostLastMessage: boolean;
  }) => {
    const { userId } = useCurrentUser();
    const { doneVoting, sendChatMessage } = useSocket();
    const { solutions } = useSolutions();
    const { result: suggestions, completed } = message;
    const [isDoneVoting, setIsDoneVoting] = useState(false);

    const handleDoneVoting = () => {
      setIsDoneVoting(true);
      doneVoting();
    };

    const handleMoreOptions = () => {
      sendChatMessage({
        messageText: "More options!",
        chatId: "ai",
      });
    };
    const doneVotingActive = true; // voteInteractionsCount > 0

    return (
      <div className="solution-meta">
        <div className="solution-container" id={`solution-${message.id}`}>
          {suggestions &&
            suggestions.map((suggestion, index) => {
              if (!suggestion) return null;
              const matchingSolution = solutions.find(
                (s) => s.id === suggestion.id
              );
              const existingVotes = matchingSolution?.votes || {};
              const existingVerdicts = matchingSolution?.verdicts || {};

              suggestion = { ...matchingSolution, ...suggestion }; // TODO remove uses of matchingSolution below

              return (
                <div
                  key={index}
                  className={`suggestion-card ${
                    existingVerdicts[userId]?.verdict === "fine"
                      ? "solution-fine"
                      : ""
                  } ${
                    // @ts-ignore TODO
                    existingVerdicts[userId]?.verdict === "discard"
                      ? "solution-discarded"
                      : ""
                  }`}
                >
                  {matchingSolution?.originalUrl && (
                    <div className="thumbnail">
                      <img src={matchingSolution.originalUrl} alt="" />
                    </div>
                  )}
                  <div className="suggestion-top-row">
                    {suggestion.origin && (
                      <CustomTooltip
                        id={`tooltip-sidebar-${suggestion.id}`}
                        content={
                          suggestion.origin === "idea"
                            ? "This idea was generated by the AI, and might be a hallucination"
                            : ""
                        }
                      >
                        <span className="origin-icon">
                          {suggestion.origin === "idea" ? (
                            <i
                              className="far fa-lightbulb"
                              aria-hidden="true"
                            ></i>
                          ) : suggestion.origin === "internet_search" ? (
                            <i className="fas fa-globe" aria-hidden="true"></i>
                          ) : null}
                        </span>
                      </CustomTooltip>
                    )}
                    <h4 className="suggestion-title">{suggestion.title}</h4>
                  </div>
                  {suggestion.properties &&
                    suggestion.properties.length > 0 && (
                      <div className="solution-properties">
                        {suggestion.properties.map(
                          (property, propIndex) =>
                            property.key &&
                            property.val && (
                              <p key={propIndex}>
                                <strong>{property.key}</strong>: {property.val}
                              </p>
                            )
                        )}
                      </div>
                    )}
                  {suggestion?.description && (
                    <p className="suggestion-description">
                      {suggestion.description}
                    </p>
                  )}
                  {!suggestion?.partial && (
                    <PreferencesContainer
                      solutionId={suggestion.id}
                      activeVote={existingVotes[userId]?.vote || "neutral"}
                      activeVerdict={
                        existingVerdicts[userId]?.verdict || "unknown"
                      }
                      type="solution_suggestion"
                    />
                  )}
                  <SourceLink sourceLink={suggestion.sourceLink} />
                </div>
              );
            })}
        </div>
        <div className="below-suggestion-buttons">
          {isAlmostLastMessage && completed && (
            <TactileButton
              className={`done-voting-button ${
                doneVotingActive ? "done-voting-active" : "done-voting-inactive"
              } ${isDoneVoting ? "clicked" : ""}`}
              onClick={() => (doneVotingActive ? handleDoneVoting() : null)}
            >
              {"Done voting"}
            </TactileButton>
          )}
          {isAlmostLastMessage && completed && (
            <TactileButton
              className={`more-options-button`}
              onClick={() => handleMoreOptions()}
            >
              {"More options"}
            </TactileButton>
          )}
        </div>
      </div>
    );
  }
);

const SolutionChip = ({ solution }) => {
  const [isHovered, setIsHovered] = useState(false);
  const [previewPosition, setPreviewPosition] = useState({ left: 0, top: 0 });
  const chipRef = useRef(null);

  const handleMouseEnter = () => {
    if (chipRef.current) {
      const rect = chipRef.current.getBoundingClientRect();
      setPreviewPosition({
        left: rect.left - 320,
        top: rect.top - 120, // Position the preview above the chip
      });
    }
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <span
      ref={chipRef}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className="solution-chip"
    >
      {solution.title}
      {isHovered && (
        <SolutionCardPreview
          solution={solution}
          isOpen={isHovered}
          position={previewPosition}
        />
      )}
    </span>
  );
};

function preprocessContent(content) {
  return content.replace(/<sol>(.*?)<\/sol>/g, (match, p1) => {
    return `<sol data-json="${encodeURIComponent(p1)}"></sol>`;
  });
}

const ThinkingBlock = ({ children }) => {
  const { isAdmin } = useCurrentUser();
  return isAdmin && <span className="thinking">{children}</span>;
};

const FunctionBlock = ({ children }) => {
  return <span className="function">{children}</span>;
};

const SourceLink = ({ sourceLink }) => {
  if (!sourceLink) return null;
  let url;
  try {
    url = new URL(sourceLink);
  } catch (e) {
    return null;
  }
  const hostname = url.hostname.replace(/^www\./, "");

  return (
    <a
      href={sourceLink}
      target="_blank"
      rel="noopener noreferrer"
      className="source-link"
    >
      <div className="source-link-container">
        <i className="fa fa-link" aria-hidden="true"></i> {hostname}
      </div>
    </a>
  );
};

const OfferForConfirmation = ({ message }) => {
  // TODO: type
  const { userId } = useCurrentUser();
  const { solutions } = useSolutions();
  const { result: proposedSolution } = message;

  if (!proposedSolution) {
    return null;
  }
  const { title, description, originalUrl } = proposedSolution;
  const existingSolution =
    solutions.find((s) => s.id === proposedSolution.id) || {};
  // @ts-ignore TODO type
  const votes = existingSolution.votes || {};
  // @ts-ignore TODO type
  const verdicts = existingSolution.verdicts || {};

  return (
    <div className="solution-container">
      <div
        className={`suggestion-confirmation-card ${
          verdicts?.[userId]?.verdict === "fine" ? "solution-fine" : ""
        }`}
      >
        <div>
          {originalUrl && (
            <div className="thumbnail">
              <img src={originalUrl} alt="" />
            </div>
          )}
          <h4 className="suggestion-title">{title}</h4>
          <p className="suggestion-description">{description}</p>
        </div>
        <PreferencesContainer
          solutionId={proposedSolution.id}
          activeVote={votes?.[userId]?.vote || "neutral"}
          activeVerdict={verdicts?.[userId]?.verdict || "unknown"}
          type="offer_for_confirmation"
        />
      </div>
    </div>
  );
};

const AgreedSolution = ({ message }) => {
  const { solution } = message;
  return (
    <div className="solution-container">
      <div className="suggestion-card solution-fine">
        {solution.originalUrl && (
          <div className="thumbnail">
            <img src={solution.originalUrl} alt="Solution Thumbnail" />
          </div>
        )}
        <div className="solution-content">
          <div className="solution-title">{solution.title}</div>
          <p className="solution-description">{solution.description}</p>
        </div>
      </div>
    </div>
  );
};

export const Messages = ({ chatId }) => {
  const { aiMessages, groupMessages } = useSocket();
  const { isAdmin } = useCurrentUser();

  const messagesEndRef = useRef(null);

  const prevMessagesLengthRef = useRef(0);

  useEffect(() => {
    const currentMessages = chatId === "ai" ? aiMessages : groupMessages;

    // Only scroll if new messages were added to the current chat
    if (currentMessages.length > prevMessagesLengthRef.current) {
      messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    }

    // Update the previous message length
    prevMessagesLengthRef.current = currentMessages.length;
  }, [aiMessages, groupMessages, chatId]);

  const messagesRaw = chatId === "ai" ? aiMessages : groupMessages;

  const messages = messagesRaw.map((message, index): React.ReactNode => {
    const messageType: MessageType = message.type;
    switch (messageType) {
      case "normal":
      case "text" as any:
        return <ChatMessage key={index} message={message} />;
      case "multiple_choice":
        return (
          <MultipleChoice
            key={index}
            message={message}
            isLastMessage={index >= messagesRaw.length - 2} // TODO: handle this properly, ugly fix for now
          />
        );
      case "solution_suggestion":
        return (
          <SolutionSuggestions
            key={index}
            message={message}
            isAlmostLastMessage={index >= messagesRaw.length - 2}
          />
        );
      case "agreed_solution" as any:
        // TODO this will disappear on refresh
        return <AgreedSolution key={index} message={message} />;
      case "offer_for_confirmation":
        return <OfferForConfirmation key={index} message={message} />;
      case "youtube_video":
        return <YoutubeVideo key={index} message={message} />;
      case "search_web":
        return (
          isAdmin && <FunctionResultMessage key={index} message={message} />
        );
      default:
        console.error("Unrecognized message type: " + messageType);
        return <div />;
    }
  });

  return (
    <div className="messages">
      {messages}
      {chatId === "ai" && <ThinkingAnimation />}
      <div className="messages-end" ref={messagesEndRef} />
    </div>
  );
};

export const MessageForm = ({ chatId }) => {
  const { checkIsConnected, reconnect, sendChatMessage, emitTypingEvent } =
    useSocket();
  const [inputValue, setInputValue] = useState("");
  let typingTimeoutId = useRef(null);

  const throttledSetTyping = useMemo(
    () =>
      throttle(() => emitTypingEvent(true, chatId), 1000, {
        leading: true,
        trailing: false,
      }),
    [emitTypingEvent, chatId]
  );

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
    handleTyping();
  };

  const handleSendMessage = async (e) => {
    e.preventDefault();
    if (!checkIsConnected()) {
      await reconnect();
    }

    if (inputValue.trim() !== "") {
      sendChatMessage({ messageText: inputValue, chatId });
      setInputValue("");
    }
    emitTypingEvent(false, chatId);
    clearTimeout(typingTimeoutId.current);
  };

  const handleTyping = () => {
    if (typingTimeoutId.current) {
      clearTimeout(typingTimeoutId.current);
    }

    throttledSetTyping();

    typingTimeoutId.current = setTimeout(() => {
      emitTypingEvent(false, chatId);
    }, 3000);
  };

  return (
    <form id="form" onSubmit={handleSendMessage}>
      <textarea
        id="input"
        className="input-box"
        value={inputValue}
        onChange={handleInputChange}
        onKeyDown={(e) =>
          e.key === "Enter" && !e.shiftKey && handleSendMessage(e)
        }
      ></textarea>
      <button className="sendButton">
        <i className="fa fa-paper-plane" aria-hidden="true"></i>
      </button>
    </form>
  );
};

export const OctopusAI = ({ thinking, position, style = "light" }) => {
  const { isConnected } = useSocket();

  return (
    <div className={`octopus-ai-container ${position}`}>
      <Canvas style={{ background: "transparent" }}>
        <IndieSphere
          thinking={thinking}
          isConnected={isConnected}
          style={style}
        />
        {/* <Sphere thinking={thinking} isConnected={isConnected} style={style} /> */}
      </Canvas>
    </div>
  );
};

export const WelcomeMessage = () => {
  const { sendStartMessage, goal, handleGoalChange, justSolveIt, thinking } =
    useSocket();
  const [welcomeStartClicked, setWelcomeStartClicked] = useState(false);

  const handleStartButtonClick = () => {
    setWelcomeStartClicked(true);
  };

  const handleSubmitClick = () => {
    sendStartMessage();
  };

  const handleJustSolveItClick = () => {
    justSolveIt();
  };

  const goalIsAlmostEmpty = () => goal.trim().length < 7;

  return (
    <div id="welcome-message">
      <OctopusAI position="welcome-octopus" thinking={thinking} />
      {!welcomeStartClicked ? (
        <>
          <button id="startButton" onClick={handleStartButtonClick}>
            Start{" "}
            <i className="fa fa-play" id="play-icon" aria-hidden="true"></i>
          </button>
        </>
      ) : (
        <>
          <div id="welcome-text">Enter goal</div>
          <textarea
            id="goal-input-copy"
            autoComplete="on"
            className="input-box goal-input-prominent"
            value={goal}
            onChange={(e) => handleGoalChange(e.target.value, false)}
            onKeyDown={(e) =>
              e.key === "Enter" && !goalIsAlmostEmpty() && handleSubmitClick()
            }
          ></textarea>
          <div id="startButtonContainer">
            <button
              id="submitGoalButton"
              onClick={handleSubmitClick}
              disabled={goalIsAlmostEmpty()}
            >
              <div id="submit-text">Submit</div>
              <div className="enter-icon-button">
                <span className="material-icons-outlined">keyboard</span>
                <span className="material-icons-outlined">keyboard_return</span>
              </div>
            </button>
            <button
              id="justSolveItButton"
              onClick={handleJustSolveItClick}
              disabled={goalIsAlmostEmpty()}
            >
              Just solve it ✨
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export const Chat = ({ chatId }) => {
  const { phase } = useSocket();
  const isStarted = () => phase !== "not_yet_started";

  return (
    <div id="chat-section">
      {!isStarted() && <WelcomeMessage />}
      {isStarted() && <Messages chatId={chatId} />}
      {isStarted() && <MessageForm chatId={chatId} />}
    </div>
  );
};

const copyToClipboard = async (inviteLink, setCopySuccess) => {
  try {
    await navigator.clipboard.writeText(inviteLink);
    // setCopySuccess("Link copied to clipboard!");
    setTimeout(() => {
      setCopySuccess(""); // Clear the message after 1.2 seconds
    }, 1200);
  } catch (err) {
    console.error("Failed to copy: ", err);
    setCopySuccess("Failed to copy to clipboard");
  }
};

export const InviteModal = ({ isOpen, onRequestClose, style }) => {
  const { username } = useCurrentUser();
  const [qrCodeSrc, setQrCodeSrc] = useState("");
  const [copyIcon, setCopyIcon] = useState("📋");
  const [copySuccess, setCopySuccess] = useState("");
  const inviteLink = useMemo(() => `${window.location.href}`, [username]);

  useEffect(() => {
    qrcode.toDataURL(
      inviteLink,
      {
        errorCorrectionLevel: "L",
        color: {
          dark: "#46644a",
          light: "#efe2a4",
        },
      },
      (err, url) => {
        if (err) {
          console.error("Error generating QR code", err);
          return;
        }
        setQrCodeSrc(url);
      }
    );
    // Assuming copyToClipboard is a function defined elsewhere
    // copyToClipboard(inviteLink, setCopySuccess);
  }, [inviteLink]);

  const handleCopy = () => {
    setCopyIcon("✅");
    // Assuming copyToClipboard is a function defined elsewhere
    copyToClipboard(inviteLink, setCopySuccess);
  };

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      // parentSelector={() => document.querySelector('#root')}
      contentLabel="Invite Link Modal"
      ariaHideApp={false}
      style={style}
    >
      {/* <button className="close-button" onClick={onRequestClose}>
        &times;
      </button> */}
      <p id="invite-text">Anyone with this link can join the room:</p>
      <div className="invite-link-container">
        <code id="link-text">{inviteLink}</code>
        <button className="copy-icon" onClick={handleCopy}>
          {copyIcon}
        </button>
      </div>
      {qrCodeSrc && (
        <div id="qr-code">
          <img src={qrCodeSrc} alt="QR Code" />
        </div>
      )}
      {copySuccess && <div className="copy-success-message">{copySuccess}</div>}
    </ReactModal>
  );
};

export const OutOfCreditsModal = () => {
  const { outOfCredits } = useSocket();

  return (
    <ReactModal
      isOpen={outOfCredits}
      // parentSelector={() => document.querySelector('#root')}
      contentLabel="Out of credits Modal"
      ariaHideApp={false}
    >
      <div className="modal">
        <div className="modal-content">
          <h3>Out of Credits</h3>
          <p>You are out of credits for this room.</p>
          <p>
            At the moment you can't buy more. But you can start a new room. Or
            DM{" "}
            <a
              href="https://x.com/jlagerros/status/1811242204352151812"
              style={{ color: "green" }}
            >
              Jacob (who made this app) on Twitter, and I'll top you up.
            </a>
            .
          </p>
          <p>All your progress in this room has been saved.</p>
        </div>
      </div>
    </ReactModal>
  );
};
