import React, { useEffect, useState, useRef, useMemo, memo } from "react";
import "../styles/App.css";
import {
  useSocket,
  useAppState,
  useCurrentUser,
  useSolutions,
} from "../services/StateProviders";
import {
  useNavigate,
  BrowserRouter as Router,
  Route,
  Routes,
  useParams,
} from "react-router-dom";
import hljs from "highlight.js";
import "highlight.js/styles/atom-one-dark.css";
import json from "highlight.js/lib/languages/json";
import "react-cmdk/dist/cmdk.css";
import { DockviewReact } from "dockview";
import ReactModal from "react-modal";
import { ErrorBoundary } from "react-error-boundary";
import GitHubIcon from "../assets/icons/github-icon.svg";
import GmailIcon from "../assets/icons/gmail-icon.svg";
import NotionIcon from "../assets/icons/notion-icon.svg";
import AsanaIcon from "../assets/icons/asana-icon.svg";
import GoogleDocsIcon from "../assets/icons/google-docs-icon.svg";
import SlackIcon from "../assets/icons/slack-icon.svg";
import AirtableIcon from "../assets/icons/airtable-icon.svg";

import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { dark } from "react-syntax-highlighter/dist/esm/styles/prism";
import { LocalStateProvider, SocketProvider } from "../services/StateProviders";
import {
  ClientUser,
  Ratings,
  Solution,
  UserRoomMetadata,
  imagined_option_name,
  meta_name,
  real_option_name,
  sketch_name,
  MessageType,
  model_estimated_update,
} from "../shared_types";
import { ACCOUNTING_UNIT, AI_NAME, DEFAULT_SPEND_LIMIT } from "../shared_flags";

import { PartyPage } from "./Party";
import {
  Chat,
  CustomTooltip,
  InputUsernameModal,
  InviteModal,
  Messages,
  OctopusAI,
  OutOfCreditsModal,
  PreferencesContainer,
  SolutionCardPreview,
  TactileButton,
  TopSolutions,
  WelcomeMessage,
} from "./Components";

// ReactModal.setAppElement('#root'); // TODO not working

const Doc = () => {
  return <></>;
};

// import CommandPalette, { filterItems, getItemIndex } from "react-cmdk";

hljs.registerLanguage("json", json);

const HomePage = () => {
  const navigate = useNavigate();
  const { roomId, handleCreateRoom } = useSocket();

  useEffect(() => {
    if (roomId) {
      navigate(`/party/room/${roomId}`);
    }
  }, [roomId, navigate]);

  return (
    <div className="container">
      {/* <OctopusAI position="welcome-octopus" thinking={false} style={"dark"} /> */}
      <div className="title">Chord</div>
      {/* <button id="createRoomButton" onClick={handleCreateRoom}>
        New coordination room
      </button> */}
      <TactileButton
        className={"create-room-button"}
        onClick={handleCreateRoom}
      >
        New room
      </TactileButton>
      <div className="x-logo-container">
        <a
          href="https://x.com/jlagerros/status/1811242204352151812"
          target="_blank"
          rel="noreferrer"
        >
          <svg className="x-logo" viewBox="0 0 24 24" aria-hidden="true">
            <g>
              <path d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z"></path>
            </g>
          </svg>
        </a>
      </div>
    </div>
  );
};

const RoomPage = () => {
  const { userId } = useCurrentUser();
  const { setRoomId, errors, joiningInProgress } = useSocket();
  const { roomId: urlRoomId } = useParams();
  const { leftSidebarOpen, setLeftSidebarOpen } = useAppState();

  useEffect(() => {
    setRoomId(urlRoomId);
  }, [urlRoomId, setRoomId]);

  const isMobile = window.innerWidth < 768;

  return (
    <div className="app">
      {errors.map((error, index) => (
        <ErrorComponent error={error} key={index} />
      ))}
      <Header />
      <InputUsernameModal userId={userId} />
      <OutOfCreditsModal />

      {/* <InputUsernameModal userId={userId}/> */}

      <div id="main-container">
        {/* <Example /> */}
        {joiningInProgress || !userId ? (
          <div className="middle-section">
            <div id="initial-loader">
              <OctopusAI position="welcome-octopus" thinking={true} />
            </div>
          </div>
        ) : (
          <>
            <LeftSidebar />
            <div className="middle-section">
              <DockviewContainer />
            </div>
          </>
        )}
      </div>
      {isMobile && (
        <MobileBottomPanel
          leftSidebarOpen={leftSidebarOpen}
          setLeftSidebarOpen={setLeftSidebarOpen}
        />
      )}
    </div>
  );
};

const App = () => {
  return (
    <Router>
      <LocalStateProvider>
        <SocketProvider>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/react/room/:roomId" element={<RoomPage />} />
            <Route path="/party/room/:roomId" element={<PartyPage />} />
          </Routes>
        </SocketProvider>
      </LocalStateProvider>
    </Router>
  );
};

export default App;

const MobileBottomPanel = ({ leftSidebarOpen, setLeftSidebarOpen }) => {
  return (
    <div className="mobile-bottom-panel">
      <button
        className="open-settings"
        onClick={() => setLeftSidebarOpen(!leftSidebarOpen)}
      >
        <i className="fa fa-cog"></i>
      </button>
      <button className="open-tabs">
        <i className="fas fa-boxes"></i>
      </button>
    </div>
  );
};

const Header = () => {
  return (
    <header className="main-header">
      <div id="logo-section">
        <a href="/">chord.ai</a>
      </div>
      <UserInfo />
    </header>
  );
};

const UserInfo = () => {
  const { username } = useCurrentUser();
  const { userRoomMetadatas } = useSocket();
  const [isRoomListVisible, setIsRoomListVisible] = useState(false);

  const showRoomList = () => setIsRoomListVisible(true);
  const hideRoomList = () => setIsRoomListVisible(false);

  if (!username) {
    return null;
  }

  // Helper function to determine the time bucket for a given room
  const getTimeBucket = (created) => {
    if (typeof created === "undefined") return "Older";
    const now = Date.now();
    const oneDay = 24 * 60 * 60 * 1000; // milliseconds in a day
    const oneWeek = 7 * oneDay;
    const oneMonth = 30 * oneDay; // approximate month in milliseconds
    const timeDiff = now - created;

    if (timeDiff < oneDay) return "Today";
    if (timeDiff < 2 * oneDay) return "Yesterday";
    if (timeDiff < oneWeek) return "Within a week";
    if (timeDiff < oneMonth) return "Within a month";
    return "Older";
  };

  // Sort rooms by the created timestamp, most recent first
  const sortedRooms = userRoomMetadatas.sort(
    (a, b) => (b.created || 0) - (a.created || 0)
  );

  // Group rooms by time buckets
  const groupedRooms: { [bucket: string]: UserRoomMetadata[] } =
    sortedRooms.reduce((acc, room) => {
      const bucket = getTimeBucket(room.created);
      if (!acc[bucket]) {
        acc[bucket] = [];
      }
      acc[bucket].push(room);
      return acc;
    }, {});

  const roomList = Object.entries(groupedRooms).map(([bucket, rooms]) => (
    <React.Fragment key={bucket}>
      <div className="time-bucket">{bucket}</div>
      {rooms.map((room, idx) => (
        <div key={room.roomId + idx} className="room-list-item">
          <a href={`/room/${room.roomId}`}>
            {room.name}
            <div className="room-users">
              {room.users.map((user) => user.username).join(", ")}
            </div>
          </a>
        </div>
      ))}
    </React.Fragment>
  ));

  return (
    <div id="user-section" onMouseLeave={hideRoomList}>
      <div id="user-info-header">
        <Credits />
        {/* <div id="user-info">{username}</div> */}
        <div className="menu-icon" onMouseEnter={showRoomList}>
          &#9776;
        </div>
      </div>
      {isRoomListVisible && (
        <div className="room-list" onMouseEnter={showRoomList}>
          {roomList}
        </div>
      )}
    </div>
  );
};

const ErrorComponent = ({ error }) => {
  if (!error.display) {
    return null;
  }

  return <div className="error-box">Error: {error.message}</div>;
};

const LeftSidebar = () => {
  const { isNerdModeOn, leftSidebarOpen, setLeftSidebarOpen } = useAppState();
  const { userId } = useCurrentUser();
  const { thinking, phase, preferenceSummaries } = useSocket();
  const sidebarRef = useRef(null);
  const isStarted = phase === "started";

  const summaryString = preferenceSummaries?.[userId]?.summary ?? "";
  const preferencePoints = summaryString
    .split("\n")
    .filter((bullet) => bullet.trim().length > 0);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        setLeftSidebarOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setLeftSidebarOpen]);

  return (
    <>
      <div
        className={`left-sidebar ${leftSidebarOpen ? "open" : ""}`}
        ref={sidebarRef}
      >
        <RoomInfo />

        {isNerdModeOn && <CreatorStats />}
        {isStarted && <OctopusAI position="left-octopus" thinking={thinking} />}
        {preferencePoints.length > 0 && (
          <div id="preference-summary">
            <div className="preferenceTitle">Your preferences</div>
            <div className="preferenceSubtitle">Inferred by {AI_NAME}</div>
            {preferencePoints.map((bullet, index) => (
              <div className="preferenceItem" key={index}>
                {bullet}
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  );
};

const RoomInfo = () => {
  const { username } = useCurrentUser();
  const { roomId, users, goal, handleGoalChange, userRoomMetadatas } =
    useSocket();
  const [showModal, setShowModal] = useState(false);
  const [isGoalInputFocused, setIsGoalInputFocused] = useState(false);

  const handleInviteParticipants = () => {
    setShowModal(true);
  };

  const roomName = userRoomMetadatas
    ? userRoomMetadatas.find((room) => room.roomId === roomId)?.name
    : "";

  return (
    <div id="room-info">
      <div id="room-id">{roomName}</div>
      <div id="connections-container">
        <div id="connections">
          {users.map((user) => (
            <div key={user.userId} className="connection-name">
              <span
                className={`${
                  user.isTyping
                    ? "typing-animation"
                    : user.status === "online"
                    ? "active-dot"
                    : "offline-dot"
                }`}
              ></span>
              {user.username}
            </div>
          ))}
        </div>
        <div className="invite-participants" onClick={handleInviteParticipants}>
          + Invite
        </div>
        <InviteModal
          isOpen={showModal}
          onRequestClose={() => setShowModal(false)}
          style={{
            overlay: {
              // backgroundColor: "#000",
            },
            content: {
              width: "500px",
              height: "300px",
              backgroundColor: "#2c2c3a",
              opacity: 1,
              position: "absolute",
              left: "35%",
              top: "20%",
            },
          }}
        />
        {!!goal && (
          <div id="goal-box" className={isGoalInputFocused ? "focused" : ""}>
            <div id="goal-title">
              Goal{" "}
              <i className="fa fa-pen" id="pen-icon" aria-hidden="true"></i>
            </div>
            <textarea
              id="goal-input"
              autoComplete="on"
              className="input-box"
              value={goal}
              onChange={(e) => handleGoalChange(e.target.value, true)}
              onFocus={() => setIsGoalInputFocused(true)}
              onBlur={() => setIsGoalInputFocused(false)}
            ></textarea>
          </div>
        )}
      </div>
    </div>
  );
};

const ContextDrop = () => {
  const { roomId } = useSocket();

  const [files, setFiles] = useState([]);
  const fileInputRef = useRef(null); // Create a reference for the file input

  const handleFileUpload = (e) => {
    const uploadedFiles = Array.from(e.target.files);
    setFiles((prevFiles) => [...prevFiles, ...uploadedFiles]);

    // Handle file upload logic (send to server, etc.)
    const formData = new FormData();
    for (let i = 0; i < uploadedFiles.length; i++) {
      // @ts-ignore TODO
      formData.append("files", uploadedFiles[i]);
    }

    fetch(`/${roomId}/upload`, {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        if (response.ok) {
          console.log("Files uploaded successfully");
        }
      })
      .catch((error) => console.error("Error uploading files:", error));
  };

  return (
    <div id="context-drop">
      <div id="dropped-files-container">
        {files.map((file) => (
          <div key={file.name} className="dropped-file">
            {file.name}
          </div>
        ))}
      </div>
      <div id="drop-box" onClick={() => fileInputRef.current.click()}>
        {/* Use the reference to trigger click */}
        <div id="file-drop-text">Drop files here...</div>
        <input
          type="file"
          id="file-input"
          multiple
          onChange={handleFileUpload}
          ref={fileInputRef}
        />
        {/* Assign the reference to the input */}
      </div>
    </div>
  );
};

const NerdMode = () => {
  const { isNerdModeOn, toggleNerdMode } = useAppState();

  return (
    <div id="nerd-mode-container">
      <label className="switch">
        <input
          type="checkbox"
          id="nerd-mode-toggle"
          checked={isNerdModeOn}
          onChange={toggleNerdMode}
        />
        <span className="slider round"></span>
      </label>
      <div id="nerd-mode-text">Nerd mode</div>
    </div>
  );
};

const CreatorStats = () => {
  const { creatorsWithStats } = useSocket();

  return (
    <div id="creatorsWithStats-section">
      <div id="creatorsWithStats-title">Solution creatorsWithStats</div>
      <div id="creatorsWithStats">
        {creatorsWithStats.map((creator, index) => (
          <div key={index} className="creator-item">
            <div className="creator-name">{creator.name}</div>
            <div className="creator-description">{creator.description}</div>
            <div className="creator-stats">
              {Object.entries(creator.stats)
                .filter(([key, value]) => value !== 0)
                .map(([key, value]) => `${key}: ${Math.round(value)}`)
                .join("\n")}
            </div>
            {creator.solutions.length > 0 && (
              <div className="creator-solutions">
                Solutions: {creator.solutions.map((s) => s.title).join(", ")}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

const ChatTab = ({ chatId, isVisible, onDidVisibilityChange }) => {
  const [visible, setVisible] = useState(isVisible);
  onDidVisibilityChange((event) => {
    setVisible(event.isVisible);
  });

  const [prevCount, setPrevCount] = useState(0);
  const [unreadCount, setUnreadCount] = useState(0);
  const { userId } = useCurrentUser();
  const { aiMessages, groupMessages, users } = useSocket();
  const { highlightGroupChat } = useAppState();
  const messages = chatId === "ai" ? aiMessages : groupMessages;

  useEffect(() => {
    if (visible) {
      setUnreadCount(0);
    } else {
      const unread = unreadCount + messages.length - prevCount;
      setUnreadCount(unread);
    }
    setPrevCount(messages.length);
  }, [visible, prevCount, messages.length, unreadCount]);

  const getLastMessage = (messages, chatId) => {
    const lastMessage = messages
      .filter((message) => message.type === "text")
      .pop();
    if (!lastMessage) {
      return "";
    }

    const messageText = lastMessage.content;

    let prefix = "";
    if (lastMessage.sender === "gpt") {
      prefix = "AI: ";
    } else if (lastMessage.sender === "user" && lastMessage.userId === userId) {
      prefix = "You: ";
    } else if (
      chatId === "group" &&
      lastMessage.sender === "user" &&
      lastMessage.userId !== userId
    ) {
      const user = users.find((user) => user.userId === lastMessage.userId);
      prefix = user ? user.username + ": " : "";
    }
    return prefix + messageText;
  };

  const getParticipants = (chatId) => {
    if (chatId === "group") {
      return users;
    }
    if (chatId === "ai") {
      return [
        {
          username: "AI",
          shortname: "AI",
          status: "online",
          isTyping: false,
          typingIn: "",
        },
      ];
    }
    return [];
  };

  const participants = {
    ai: getParticipants("ai"),
    group: getParticipants("group"),
  };

  const isSomeoneTyping = (chatId) => {
    return participants[chatId].some(
      (user) =>
        user.isTyping && user.typingIn === chatId && user.userId !== userId
    );
  };

  return (
    <div
      className={`custom-tab ${
        chatId === "group" && highlightGroupChat ? "chat-option-flash" : ""
      }`}
      id={`${chatId}-chat`}
    >
      <div className="tab-title">
        {chatId === "ai" ? "AI chat" : "Group chat"}
        <span
          className={`chat-notification-badge ${
            !visible && unreadCount > 0 ? "" : "hidden"
          }`}
        >
          {unreadCount}
        </span>
      </div>
      <div className="chat-preview">
        {isSomeoneTyping(chatId)
          ? "Typing..."
          : getLastMessage(messages, chatId)}
      </div>
      {chatId !== "ai" && (
        <div className="chat-participants">
          {participants[chatId].map((participant) => (
            <div key={participant.username} className="chat-participant-circle">
              {participant.username.slice(0, 1)}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

const TopSolutionsTab = ({ isVisible, onDidVisibilityChange }) => {
  const { highlightSolutionsSidebar } = useSocket();
  const { solutions } = useSolutions();
  const [visible, setVisible] = useState(isVisible);
  const [prevSolutionsCount, setPrevSolutionsCount] = useState(0);
  const [newSolutionsCount, setNewSolutionsCount] = useState(0);
  onDidVisibilityChange((event) => {
    setVisible(event.isVisible);
  });

  useEffect(() => {
    if (visible) {
      setNewSolutionsCount(0);
    } else {
      const unseen = newSolutionsCount + solutions.length - prevSolutionsCount;
      setNewSolutionsCount(unseen);
    }
    setPrevSolutionsCount(solutions.length);
  }, [visible, prevSolutionsCount, solutions.length, newSolutionsCount]);

  return (
    <div
      className={`custom-tab ${
        highlightSolutionsSidebar ? "glowing-open-sidebar" : ""
      }`}
    >
      <div className="top-solutions-tab-title">
        <i
          className={`fa fa-lightbulb top-solutions-lightbulb`}
          aria-hidden="true"
        ></i>
        Solutions
        <span
          className={`chat-notification-badge ${
            !visible && newSolutionsCount > 0 ? "" : "hidden"
          }`}
        >
          {newSolutionsCount}
        </span>
      </div>
    </div>
  );
};

const RoomFinances = () => {
  const { finances } = useSocket();

  const customStyle = useMemo(
    () => ({
      maxWidth: "90%",
      height: "1000px",
      whiteSpace: "pre-wrap",
      overflowWrap: "break-word" as const,
      wordBreak: "break-all" as const,
      fontSize: "10px",
    }),
    []
  );

  return (
    <div className="room-finances-container">
      <SyntaxHighlighter
        style={dark}
        customStyle={customStyle}
        language={"json"}
        PreTag="div"
      >
        {String(JSON.stringify(finances, null, 2))}
      </SyntaxHighlighter>
    </div>
  );
};

const Credits = () => {
  const { userId } = useCurrentUser();
  const { finances } = useSocket();
  const spend =
    (finances?.[userId]?.spend || 0) + (finances?.shared?.spend || 0);

  const displaySpend = (spend / ACCOUNTING_UNIT).toFixed(2);
  return (
    spend > 0 && (
      <div className="credits-container">
        {displaySpend}/{Math.round(DEFAULT_SPEND_LIMIT / ACCOUNTING_UNIT)}
      </div>
    )
  );
};

// const EloDisplay = ({ ratings, solution }) => {
//   const updateEloDisplay = (eloElement, solutionRatings, solutionId) => {
//     // Clear existing ELO ratings
//     eloElement.innerHTML = '';

//     // Check if there are any ratings to display
//     if (solutionRatings) {
//       for (const [userId, userRatings] of Object.entries(solutionRatings)) {
//         const solutionRatings = userRatings[solutionId];
//         if (solutionRatings) {
//           const eloRating = document.createElement('p');
//           const lastRating = solutionRatings[solutionRatings.length - 1].rating;
//           eloRating.textContent = `${userId.replace(/\d+/g, '')}: ${Math.round(lastRating)}`;

//           // Determine the color based on the conditions
//           eloRating.style.color = determineEloColor(solutionRatings);
//           eloElement.appendChild(eloRating);
//         }
//       }
//     }
//   };

//   const determineEloColor = (solutionRatings) => {
//     if (solutionRatings.length > 1) {
//       const lastRating = solutionRatings[solutionRatings.length - 1].rating;
//       const secondLastRating = solutionRatings[solutionRatings.length - 2].rating;
//       if (lastRating > secondLastRating) {
//         return 'green';
//       } else if (lastRating < secondLastRating) {
//         return 'red';
//       } else {
//         return 'black';
//       }
//     } else if (solutionRatings.length === 1) {
//       return 'yellow';
//     }
//   };

//   return <div className="elo-display" ref={(el) => el && updateEloDisplay(el, ratings, solution.id)}></div>;
// };

// const Example = () => {
//   const [page, setPage] = useState("root");
//   const [open, setOpen] = useState(true);
//   const [search, setSearch] = useState("");

//   const filteredItems = filterItems(
//     [
//       {
//         heading: "Home",
//         id: "home",
//         items: [
//           {
//             id: "home",
//             children: "Home",
//             icon: "HomeIcon",
//             href: "#",
//           },
//           {
//             id: "settings",
//             children: "Settings",
//             icon: "CogIcon",
//             href: "#",
//           },
//           {
//             id: "projects",
//             children: "Projects",
//             icon: "RectangleStackIcon",
//             closeOnSelect: false,
//             onClick: () => {
//               setPage("projects");
//             },
//           },
//         ],
//       },
//       {
//         heading: "Other",
//         id: "advanced",
//         items: [
//           {
//             id: "developer-settings",
//             children: "Developer settings",
//             icon: "CodeBracketIcon",
//             href: "#",
//           },
//           {
//             id: "privacy-policy",
//             children: "Privacy policy",
//             icon: "LifebuoyIcon",
//             href: "#",
//           },
//           {
//             id: "log-out",
//             children: "Log out",
//             icon: "ArrowRightOnRectangleIcon",
//             onClick: () => {
//               alert("Logging out...");
//             },
//           },
//         ],
//       },
//     ],
//     search
//   );

//   return (
//     <CommandPalette
//       onChangeSearch={setSearch}
//       onChangeOpen={setOpen}
//       search={search}
//       isOpen={open}
//       page={page}
//     >
//       <CommandPalette.Page id="root">
//         {filteredItems.length ? (
//           filteredItems.map((list) => (
//             <CommandPalette.List key={list.id} heading={list.heading}>
//               {list.items.map(({ id, ...rest }) => (
//                 <CommandPalette.ListItem
//                   key={id}
//                   index={getItemIndex(filteredItems, id)}
//                   {...rest}
//                 />
//               ))}
//             </CommandPalette.List>
//           ))
//         ) : (
//           <CommandPalette.FreeSearchAction />
//         )}
//       </CommandPalette.Page>

//       <CommandPalette.Page id="projects">
//         {/* Projects page */}
//       </CommandPalette.Page>
//     </CommandPalette>
//   );
// };

const ContextIngestors = () => {
  return (
    <div className="context-ingestors">
      <div>Add context</div>
      <p>
        Drop files here to share them with everyone in the workspace as well as
        Chord AI.
      </p>
      <ContextDrop />

      <p>
        Or directly sync a context source to keep your workspace up to date with
        any updates.
      </p>
      <div className="context-sources">
        <div className="context-source-icon">
          <img src={GitHubIcon} alt="GitHub" />
        </div>
        <div className="context-source-icon">
          <img src={GmailIcon} alt="GitHub" />
        </div>
        <div className="context-source-icon">
          <img src={NotionIcon} alt="GitHub" />
        </div>
        <div className="context-source-icon">
          <img src={AsanaIcon} alt="GitHub" />
        </div>
        <div className="context-source-icon">
          <img src={GoogleDocsIcon} alt="GitHub" />
        </div>
        <div className="context-source-icon">
          <img src={SlackIcon} alt="GitHub" />
        </div>
        <div className="context-source-icon">
          <img src={AirtableIcon} alt="GitHub" />
        </div>
      </div>
    </div>
  );
};

const ExpandableJson = ({ data, previewLength = 20 }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <span className="data-preview" onClick={() => setIsExpanded(!isExpanded)}>
      {isExpanded ? (
        <pre className="json-data">
          <code className="json">{JSON.stringify(data, null, 2)}</code>
        </pre>
      ) : (
        `${JSON.stringify(data, null, 2).slice(0, previewLength)}...`
      )}
    </span>
  );
};

const QueueDisplay = ({ queueName, title }) => {
  const { matchData, brainstormQueueData } = useSocket();
  const queues = {
    matchData: matchData,
    brainstormQueueData: brainstormQueueData,
  };
  const queueData = queues[queueName];

  if (!queueData) {
    return <div>Loading...</div>;
  }

  const jobTypes = ["waiting", "succeeded", "active", "delayed", "failed"];

  const getJobSummary = (jobs) => {
    return jobs.map((job, index) => (
      <div key={index} className="job-summary">
        <p>
          <strong>Job ID:</strong> {job.id}
        </p>
        <p>
          <strong>Progress:</strong> {job.progress}
        </p>
        <p>
          <strong>_events:</strong> <ExpandableJson data={job._events} />
        </p>
        <p>
          <strong>_eventsCount:</strong> {job._eventsCount}
        </p>
        <p>
          <strong>queue:</strong> <ExpandableJson data={job.queue} />
        </p>
        <p>
          <strong>options:</strong> <ExpandableJson data={job.options} />
        </p>
        <p>
          <strong>Data:</strong> <ExpandableJson data={job.data} />
        </p>
        <p>
          <strong>Status:</strong> {job.status}
        </p>
      </div>
    ));
  };

  const getSummaryMetadata = () => {
    return jobTypes.map((type) => (
      <div key={type} className="summary-metadata">
        {!!queueData[type] && (
          <p>
            <strong>{type}:</strong> {queueData[type].length}
          </p>
        )}
      </div>
    ));
  };

  return (
    <div className="queue-container">
      <h3>{title}</h3>
      <div className="summary-metadata-container">{getSummaryMetadata()}</div>
      {jobTypes.map(
        (type) =>
          !!queueData[type] && (
            <div key={type} className="job-type-section">
              <h3>{type}</h3>
              {getJobSummary(queueData[type])}
            </div>
          )
      )}
    </div>
  );
};

const CustomTab = (props) => {
  return (
    <div {...props} className="custom-tab tab-title">
      {props.api.title}
    </div>
  );
};

const tabComponents = {
  chat: (props) => {
    return (
      <ChatTab
        chatId={props.params.chatId}
        isVisible={props.api.isVisible}
        onDidVisibilityChange={props.api.onDidVisibilityChange}
      />
    );
  },
  topSolutions: (props) => {
    return (
      <TopSolutionsTab
        isVisible={props.api.isVisible}
        onDidVisibilityChange={props.api.onDidVisibilityChange}
      />
    );
  },
  customTab: CustomTab,
};

const components = {
  welcomeMessage: WelcomeMessage,
  messages: (props) => {
    return <Messages chatId={props.params.chatId} />;
  },

  chat: (props) => {
    return <Chat chatId={props.params.chatId} />;
  },

  doc: Doc,

  topSolutions: TopSolutions,

  roomFinances: RoomFinances,

  default: (props) => {
    return (
      <div
        style={{
          height: "100%",
          overflow: "auto",
          color: "white",
          position: "relative",
        }}
      >
        <span
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%,-50%)",
            pointerEvents: "none",
            fontSize: "42px",
            opacity: 0.5,
          }}
        >
          {props.api.title}
        </span>
      </div>
    );
  },

  context: ContextIngestors,
  queueDisplay: (props) => {
    return (
      <QueueDisplay
        queueName={props.params.queueName}
        title={props.params.title}
      />
    );
  },

  iframe: () => {
    return (
      <iframe
        title="dockview"
        style={{
          width: "100%",
          height: "100%",
        }}
        src="https://dockview.dev"
      />
    );
  },
};

function defaultConfig(api, setChatPane) {
  const chat = api.addPanel({
    id: "panel_1",
    component: "chat",
    tabComponent: "chat",
    renderer: "always",
    title: "AI chat",
    params: { chatId: "ai" },
  });

  setChatPane(chat);

  // gropu chat only added when the user joins

  // solutions only added when there are any

  // api.addPanel({
  //   id: "panel_5",
  //   component: "doc",
  //   tabComponent: "customTab",
  //   title: "Notes",
  //   position: { referencePanel: chat },
  // });

  // api.addPanel({
  //   id: "panel_6",
  //   component: "context",
  //   tabComponent: "customTab",
  //   title: "Context",
  //   position: { referencePanel: chat },
  // });

  chat.api.setActive();
}

function adminConfig(api) {
  const chat = api.addPanel({
    id: "panel_1",
    component: "chat",
    tabComponent: "chat",
    renderer: "always",
    title: "AI chat",
    params: { chatId: "ai" },
  });

  api.addPanel({
    id: "panel_2",
    component: "chat",
    tabComponent: "chat",
    title: "Group chat",
    position: { referencePanel: chat },
    params: { chatId: "group" },
  });

  const topSolutions = api.addPanel({
    id: "panel_4",
    component: "topSolutions",
    tabComponent: "topSolutions",
    title: "Solutions",
    position: { referencePanel: chat, direction: "right" },
  });

  api.addPanel({
    id: "finances",
    component: "roomFinances",
    tabComponent: "customTab",
    title: "Finances",
    position: { referencePanel: chat, direction: "right" },
  });

  api.addPanel({
    id: "panel_5",
    component: "doc",
    tabComponent: "customTab",
    title: "Notes",
    position: { referencePanel: chat },
  });

  api.addPanel({
    id: "panel_6",
    component: "context",
    tabComponent: "customTab",
    title: "Context",
    position: { referencePanel: chat },
  });

  api.addPanel({
    /// TODO all user chats
    id: "other_chats",
    component: "context",
    tabComponent: "customTab",
    title: "Context",
    position: { referencePanel: chat },
  });

  api.addPanel({
    // TODO tasks
    id: "panel_7",
    component: "context",
    tabComponent: "customTab",
    title: "Context",
    position: { referencePanel: topSolutions },
  });

  api.addPanel({
    // ELO monitor
    id: "panel_9",
    component: "queueDisplay",
    tabComponent: "customTab",
    title: "ELO Tournament",
    position: { referencePanel: topSolutions },
    params: { queueName: "matchData", title: "ELO Tournament Jobs" },
  });

  api.addPanel({
    // brainstorm queue
    id: "panel_10",
    component: "queueDisplay",
    tabComponent: "customTab",
    title: "Brainstorm Queue",
    position: { referencePanel: topSolutions },
    params: { queueName: "brainstormQueueData", title: "Brainstorming Jobs" },
  });

  topSolutions.api.setSize({ width: 250 });

  chat.api.setActive();
}

const mobileConfig = (api) => {
  const chat = api.addPanel({
    id: "panel_2",
    component: "chat",
    tabComponent: "chat",
    renderer: "always",
    title: "AI chat",
    params: { chatId: "ai" },
  });

  api.addPanel({
    id: "panel_3",
    component: "chat",
    tabComponent: "chat",
    title: "Group chat",
    position: { referencePanel: chat },
    params: { chatId: "group" },
  });

  api.addPanel({
    id: "panel_5",
    component: "doc",
    tabComponent: "customTab",
    title: "Notes",
    position: { referencePanel: chat },
  });

  chat.api.setActive();
};

const DockviewContainer = () => {
  const { users } = useSocket();
  const { solutions } = useSolutions();
  const [panels, setPanels] = useState([]);
  const [groups, setGroups] = useState([]);
  const [api, setApi] = useState();
  const [chatPanel, setChatPane] = useState();

  const [activePanel, setActivePanel] = useState();
  const [activeGroup, setActiveGroup] = useState();

  const groupChatAdded = useRef(false);
  const solutionPaneAdded = useRef(false);

  const isAdmin =
    new URLSearchParams(window.location.search).get("admin") !== null;

  const isMobile = window.innerWidth < 768;

  const onReady = (event) => {
    setApi(event.api);

    event.api.onDidAddPanel((event) => {
      setPanels((_) => [..._, event.id]);
    });
    event.api.onDidActivePanelChange((event) => {
      setActivePanel(event?.id);
    });
    event.api.onDidRemovePanel((event) => {
      setPanels((_) => {
        const next = [..._];
        next.splice(
          next.findIndex((x) => x === event.id),
          1
        );

        return next;
      });
    });

    event.api.onDidAddGroup((event) => {
      setGroups((_) => [..._, event.id]);
    });

    event.api.onDidRemoveGroup((event) => {
      setGroups((_) => {
        const next = [..._];
        next.splice(
          next.findIndex((x) => x === event.id),
          1
        );

        return next;
      });
    });

    event.api.onDidActiveGroupChange((event) => {
      setActiveGroup(event?.id);
    });

    const state = localStorage.getItem("dv-demo-state");
    if (state) {
      try {
        event.api.fromJSON(JSON.parse(state));
        return;
      } catch {
        localStorage.removeItem("dv-demo-state");
      }
      return;
    }

    if (isAdmin) {
      adminConfig(event.api);
    } else {
      if (isMobile) {
        mobileConfig(event.api);
      } else {
        defaultConfig(event.api, setChatPane);
      }
    }
  };

  useEffect(() => {
    if (users.length > 1 && !groupChatAdded.current && api && chatPanel) {
      // @ts-ignore    TODO
      api.addPanel({
        id: "group_chat_tab",
        component: "chat",
        tabComponent: "chat",
        title: "Group chat",
        position: { referencePanel: "panel_1" },
        params: { chatId: "group" },
      });
      groupChatAdded.current = true; // Mark that the group chat panel has been added
    }
  }, [users, api, chatPanel]);

  useEffect(() => {
    if (!solutionPaneAdded.current && api && chatPanel) {
      // @ts-ignore    TODO
      const solutionsPane = api.addPanel({
        id: "panel_4",
        component: "topSolutions",
        tabComponent: "topSolutions",
        title: "Solutions",
        position: { referencePanel: chatPanel, direction: "right" },
      });
      solutionsPane.api.setSize({ width: 250 });
      solutionPaneAdded.current = true; // Mark that the group chat panel has been added
    }
  }, [solutions, api, chatPanel]);

  return (
    <div
      style={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        backgroundColor: "rgba(0,0,50,0.25)",
      }}
    >
      <div
        style={{
          flexGrow: 1,
          overflow: "hidden",
          // flexBasis: 0
          height: 0,
          display: "flex",
        }}
      >
        <DockviewReact
          components={components}
          tabComponents={tabComponents}
          onReady={onReady}
          className={"dockview-theme-abyss"}
        />
      </div>
    </div>
  );
};
