import {
  createContext,
  useContext,
  useEffect,
  useState,
  Dispatch,
  SetStateAction,
  useCallback,
  useMemo,
} from "react";
import { useParams, useLocation } from "react-router-dom";
import { io } from "socket.io-client";
import html2canvas from "html2canvas";
import {
  ClientUser,
  CreatorWithStats,
  Finances,
  Ratings,
  Solution,
  TopSolutionEmitData,
  UserRoomMetadata,
  imagined_option_name,
  sketch_name,
  MessageType,
  UpdateSolutionMessage,
  AddSolutionsMessage,
  SolutionRankings,
  RankingsMessage,
  UpdateRankingsMessage,
  ClientMessage,
  ServerToClientApiMessageType,
  ServerToClientApiMessageObjTypes,
  ChatUserMessage,
  automatedMessagePrefix,
} from "../shared_types";

const socket = io(process.env.REACT_APP_BACKEND_URL, {
  reconnection: true,
  reconnectionAttempts: Infinity,
  reconnectionDelay: 1000,
  reconnectionDelayMax: 5000,
  timeout: 20000, // 20 seconds
});
// Create a context for the socket service

// TODO: remaining types

type IsThinking = boolean;
type IsConnected = boolean;
type IsAdmin = boolean;
type JoiningInProgress = boolean;
type SetVote = (data: {
  userId: string;
  solutionId: string;
  vote: number;
}) => void;
type SetVerdict = (data: {
  userId: string;
  solutionId: string;
  verdict: number;
}) => void;
type SendChatMessageParams = {
  messageText: string;
  chatId: string;
  captureScreenshot?: boolean;
};
type UserRoomMetadatas = UserRoomMetadata[]; // TODO

type CurrentUserContextType = {
  userId?: string;
  username?: string;
  isAdmin?: boolean;
};

type SolutionsContextType = {
  solutions?: Solution[];
  ratings?: Ratings;
  setSolutions?: Dispatch<SetStateAction<Solution[]>>;
  setRatings?: Dispatch<SetStateAction<Ratings>>;
  sendSolutionReaction?: any;
  sendSolutionReactionInLatestCard?: any;
  newSidebarSolutions?: any;
};

type GenerateAiTextParams = {
  text: string;
  textLength?: string;
  textUnit?: string;
  useHeading: boolean;
  tone?: string;
  language?: string;
};

type SocketContextType = {
  roomId?: string;
  setRoomId?: Dispatch<SetStateAction<string>>;
  aiMessages?: any[]; // TODO
  groupMessages?: any[]; // TODO
  goal?: string;
  userRoomMetadatas?: UserRoomMetadatas;
  users?: ClientUser[];
  errors?: any[]; //todo
  creatorsWithStats?: CreatorWithStats[];
  thinking?: IsThinking;
  highlightSolutionsSidebar?: any; // TODO
  phase?: string;
  sortAnimationSpeed?: number;
  joiningInProgress?: JoiningInProgress;
  preferenceSummaries?: any; //todo
  matchData?: any; //todo
  brainstormQueueData?: any; //todo
  handleCreateRoom?: any;
  doneVoting?: any;
  isConnected: boolean;
  checkIsConnected?: () => boolean;
  reconnect?: any;
  handleUsernameSubmit?: any;
  sendChatMessage?: (params: SendChatMessageParams) => void;
  handleGoalChange?: any;
  sendVerdictReaction?: any;
  setVote?: SetVote;
  setVerdict?: SetVerdict;
  sendStartMessage?: any;
  justSolveIt?: any;
  sendMultipleChoiceReaction?: any;
  emitTypingEvent?: any;
  setPreferenceSummaries?: any;
  finances?: Finances;
  outOfCredits?: boolean;
  rainConfetti?: boolean;
  setRainConfetti?: Dispatch<SetStateAction<boolean>>;
  chatId?: string;
  setChatId?: Dispatch<SetStateAction<string>>;
  unread?: { ai: number; group: number };
  setUnread?: Dispatch<SetStateAction<{ ai: number; group: number }>>;
  generateAiText?: (params: GenerateAiTextParams) => Promise<string>;
};

const CurrentUserContext = createContext<CurrentUserContextType>({});
const SolutionsContext = createContext<SolutionsContextType>({}); // TODO: better default value?
const SocketContext = createContext<SocketContextType | null>(null);

const getScreenshot = async () =>
  await html2canvas(document.body).then((canvas) => {
    return ""; // canvas.toDataURL("image/png");   turning this off to debug network traffic
  });

const sortSolutions = (
  solutions: Solution[],
  rankings: SolutionRankings
): Solution[] => {
  return solutions.sort(
    (a, b) => (rankings[a.id] || 0) - (rankings[b.id] || 0)
  );
};

// Create a provider component for the socket service
export const SocketProvider = ({ children }) => {
  const { setHighlightGroupChat } = useAppState();

  const storedUserId = localStorage.getItem("userId");
  const storedUsername = localStorage.getItem("username");
  const { roomId: urlRoomId } = useParams(); // React Router hook to get params
  const [roomId, setRoomId] = useState(urlRoomId);
  const [userId, setUserId] = useState(storedUserId);
  const [username, setUsername] = useState(storedUsername);
  const [email, setEmail] = useState("");
  const [phase, setPhase] = useState("not_yet_started");
  const [userRoomMetadatas, setUserRoomMetadatas] = useState<UserRoomMetadatas>(
    []
  );
  const [rainConfetti, setRainConfetti] = useState(false);
  const [aiMessages, setAiMessages] = useState([]);
  const [groupMessages, setGroupMessages] = useState([]);
  const [unread, setUnread] = useState({ ai: 0, group: 0 });
  const [chatId, setChatId] = useState("ai");

  const [users, setUsers] = useState<ClientUser[]>([]);
  const [solutions, setSolutions] = useState<Solution[]>([]);
  const [rankings, setRankings] = useState<SolutionRankings>({});
  const setVote = ({ userId, solutionId, vote }) => {
    setSolutions((prevSolutions) =>
      prevSolutions.map((solution) => {
        if (solution.id === solutionId) {
          return {
            ...solution,
            votes: { ...solution.votes, [userId]: { vote } },
          };
        }
        return solution;
      })
    );
  };

  const setVerdict = ({ userId, solutionId, verdict }) => {
    setSolutions((prevSolutions) =>
      prevSolutions.map((solution) => {
        if (solution.id === solutionId) {
          return {
            ...solution,
            verdicts: { ...solution.verdicts, [userId]: { verdict } },
          };
        }
        return solution;
      })
    );
  };

  const [goal, setGoal] = useState("");
  const [preferenceSummaries, setPreferenceSummaries] = useState({});
  const [errors, setErrors] = useState([]);
  const [creatorsWithStats, setCreatorsWithStats] = useState<
    CreatorWithStats[]
  >([]);
  const [highlightSolutionsSidebar, setHighlightSolutionsSidebar] =
    useState(false);
  const [thinking, setThinking] = useState<IsThinking>(false);
  const [ratings, setRatings] = useState<Ratings>({});
  const [isConnected, setIsConnected] = useState(() => socket.connected);
  const [isAdmin, setIsAdmin] = useState<IsAdmin>(false);
  const [joiningInProgress, setJoiningInProgress] =
    useState<JoiningInProgress>(false);
  const [matchData, setMatchData] = useState({});
  const [brainstormQueueData, setBrainstormQueueData] = useState({});
  const [finances, setFinances] = useState<Finances>({
    shared: { spend: 0 },
  });
  const [outOfCredits, setOutOfCredits] = useState<boolean>(false);

  const location = useLocation();

  const generateAiText = useCallback(
    async (params: GenerateAiTextParams): Promise<string> => {
      return new Promise((resolve, reject) => {
        const payload = {
          ...params,
          roomId,
          userId,
        };

        socket.emit("generate_ai_text", payload);

        socket.once("ai_text_generated", (response) => {
          const text = response.response;
          resolve(text);
        });

        socket.once("ai_text_error", (error) => {
          reject(error);
        });
      });
    },
    [roomId]
  );

  useEffect(() => {
    const isAdminParam =
      new URLSearchParams(location.search).get("admin") !== null;
    setIsAdmin(isAdminParam);
  }, [location.search]);

  const checkIsConnected = useCallback(() => {
    return socket.connected;
  }, []);

  const sortAnimationSpeed = 800;

  const handlePhase = (newPhase) => {
    setPhase(newPhase);
  };

  const handleStartMessage = (messageData) => {
    const message = {
      content: messageData.content,
      sender: messageData.sender,
      type: "text",
      targetChat: "ai",
    };
    setAiMessages([message]);
    setPhase("started");
  };

  const handleUpdateUsers = (users: ClientUser[]) => {
    setUsers(users);
  };

  const handleError = (error) => {
    setErrors((prevErrors) => [
      ...prevErrors,
      { message: error, display: true },
    ]);
  };

  const handleCreatorStats = (creatorsData: CreatorWithStats[]) => {
    setCreatorsWithStats(creatorsData);
  };

  const handleTopSolutions = (data: TopSolutionEmitData) => {
    const { ratings, rankings, solutions } = data;
    setRatings(ratings);
    setRankings(rankings);
    setSolutions(solutions);
  };

  const sendStartMessage = () => {
    setPhase("started");
    const userIds = users.map((user) => user.userId);
    socket.emit("start message", { userId, roomId, userIds, goal, users });
  };

  const justSolveIt = () => {
    setPhase("started");
    socket.emit("just solve it", { userId, roomId, goal });
  };

  const doneVoting = () => {
    socket.emit("done voting", { userId, roomId });
  };

  const newSidebarSolutions = () => {
    socket.emit("new sidebar solutions", { roomId, userId, goal });
  };

  const handleCreateRoom = () => {
    socket.emit("create room");
  };

  const register = (data) => {
    socket.emit("register", data);
  };

  const joinRoom = (data) => {
    socket.emit("join room", data);
    setJoiningInProgress(true);
  };

  const reconnect = useCallback(() => {
    return new Promise<void>((resolve, reject) => {
      socket.connect(); // Attempt to reconnect

      socket.once("connect", () => {
        resolve();
      });

      // Optionally, handle the 'connect_error' event to reject the promise if needed
      socket.once("connect_error", (error) => {
        console.log("Reconnection failed.", error);
        reject(error);
      });
    });
  }, []);

  const sendChatMessage = async ({
    messageText,
    chatId,
    captureScreenshot = true,
  }: SendChatMessageParams) => {
    let screenshot = null;

    if (captureScreenshot) {
      // Capture screenshot only if captureScreenshot is true
      screenshot = await getScreenshot();
    }

    const message = {
      roomId,
      userId,
      msg: messageText,
      screenshot, // This will be null if captureScreenshot is false
      targetChat: chatId,
    };

    if (!checkIsConnected()) {
      try {
        await reconnect(); // Wait for reconnection to succeed before proceeding
      } catch (error) {
        console.error("Reconnection failed, message not sent.", error);
        return; // Exit the function if reconnection fails
      }
    }

    socket.emit("chat_message", message);

    const messageToStore = {
      content: messageText,
      sender: "user",
      userId: userId,
      type: "text",
    };

    if (chatId === "group") {
      setGroupMessages((prevMessages) => [...prevMessages, messageToStore]);
    } else {
      setAiMessages((prevMessages) => [...prevMessages, messageToStore]);
    }
  };

  const handleGoalChange = (newGoal: string, broadcast: boolean) => {
    setGoal(newGoal);
    socket.emit("update goal", { newGoal, broadcast });
  };

  const sendSolutionReaction = (data) => {
    setSolutions((prevSolutions) =>
      prevSolutions.map((solution) => {
        if (solution.id === data.suggestionId) {
          return {
            ...solution,
            votes: { ...solution.votes, [userId]: { vote: data.vote } },
          };
        }
        return solution;
      })
    );

    socket.emit("solution reaction", { ...data, roomId, userId });
  };

  const sendSolutionReactionInLatestCard = (data) => {
    socket.emit("solution reaction in latest card", data);
  };

  const sendVerdictReaction = (data) => {
    // optimistically update solutions
    setSolutions((prevSolutions) =>
      prevSolutions.map((solution) => {
        if (solution.id === data.suggestionId) {
          return {
            ...solution,
            verdicts: {
              ...solution.verdicts,
              [userId]: { verdict: data.verdict },
            },
          };
        }
        return solution;
      })
    );

    socket.emit("verdict reaction", { ...data, roomId, userId });
  };

  const sendMultipleChoiceReaction = async (data) => {
    setAiMessages((prevMessages) => {
      return prevMessages.map((message) => {
        if (message.id === data.multipleChoiceId) {
          return {
            ...message,
            result: {
              ...message.result,
              selectedOptions: data.selectedOptions,
            },
          };
        }
        return message;
      });
    });

    const screenshot = await getScreenshot();

    socket.emit("multiple choice reaction", {
      ...data,
      screenshot,
      roomId,
      userId,
    });
  };

  const emitTypingEvent = useCallback(
    (isTyping, chatId) => {
      socket.emit("typing", { userId, roomId, chatId, isTyping });
    },
    [userId, roomId]
  );

  const handleConnect = () => setIsConnected(true);
  const handleDisconnect = useCallback(() => {
    setIsConnected(false);
    console.log("Disconnected from the server");
    const reconnectionDelay = 1000; // 5 seconds delay before attempting to reconnect
    setTimeout(() => {
      reconnect();
    }, reconnectionDelay);
  }, [reconnect]);

  useEffect(() => {
    setSolutions((prevSolutions) => {
      const sortedSolutions = sortSolutions(prevSolutions, rankings);
      return sortedSolutions;
    });
  }, [rankings]);

  useEffect(() => {
    const handleStreamingMessage = (data, messageType) => {
      const { result, id, completed } = data;
      setAiMessages((prevMessages) => {
        let messageExists = false;
        const newMessages = prevMessages.map((message) => {
          if (message.id === id && message.type === messageType) {
            messageExists = true;
            return { ...message, result, completed: !!completed };
          }
          return message;
        });
        if (!messageExists) {
          newMessages.push({
            sender: "gpt",
            type: messageType,
            id,
            result,
            completed,
          });
        }
        return newMessages;
      });
    };

    // socket.onAny((eventName, ...args) => {
    //   console.log(eventName, args);
    // });
    // Register socket event listeners
    socket.on("connect", handleConnect);
    socket.on("disconnect", handleDisconnect);
    socket.on("reconnect_attempt", () => {
      console.log("socket attempting to reconnect...");
    });

    socket.on("reconnect_error", (error) => {
      console.log("socket reconnection error:", error);
    });

    socket.on("reconnect_failed", () => {
      console.log("socket reconnection failed");
    });

    addSocketMessageHandler(socket, "joined room", () => {
      setJoiningInProgress(false);
      console.log("joined");
    });
    addSocketMessageHandler(socket, "room created", (roomId) => {
      setRoomId(roomId);
    });
    addSocketMessageHandler(socket, "phase", handlePhase);
    addSocketMessageHandler(socket, "user rooms", (data) => {
      setUserRoomMetadatas(data.rooms);
    });
    addSocketMessageHandler(socket, "start message", handleStartMessage); // todo: I think this is unused?
    addSocketMessageHandler(socket, "update clients", handleUpdateUsers);
    addSocketMessageHandler(socket, "update typing", (data) => {
      const { userId, isTyping, typingIn } = data;
      setUsers((prevUsers) => {
        return prevUsers.map((user) => {
          if (user.userId === userId) {
            return { ...user, isTyping, typingIn };
          }
          return user;
        });
      });
    });
    addSocketMessageHandler(socket, "suggestions refresh", (data) => {
      setSolutions(data.suggestions);
    });
    addSocketMessageHandler(socket, "solution_suggestion", (data) => {
      handleStreamingMessage(data, "solution_suggestion");
    });
    addSocketMessageHandler(socket, "search_web", (data) => {
      handleStreamingMessage(data, "search_web");
    });
    addSocketMessageHandler(socket, "youtube_video", (data) => {
      handleStreamingMessage(data, "youtube_video");
    });

    const updateMessagesHelper = (setMessages, message, type) => {
      setMessages((prevMessages) => {
        let messageExists = false;
        const newMessages = prevMessages.map((existingMessage) => {
          if (existingMessage.id && existingMessage.id === message.id) {
            messageExists = true;
            return { ...existingMessage, content: message.content };
          }
          return existingMessage;
        });
        if (!messageExists && message.id) {
          newMessages.push({
            sender: message.sender,
            type,
            id: message.id,
            content: message.content,
            userId: message.userId,
          });
        }
        return newMessages;
      });
    };

    addSocketMessageHandler(socket, "messages", (messages: ClientMessage[]) => {
      const shouldIgnoreMessage = (message: ChatUserMessage) => {
        return message.content.includes(automatedMessagePrefix);
      };
      messages.forEach((message) => {
        switch (message.type) {
          case "normal":
            if (message?.sender === "user" && !shouldIgnoreMessage(message)) {
              const updateMessages =
                message.targetChat === "group"
                  ? setGroupMessages
                  : setAiMessages;

              updateMessagesHelper(updateMessages, message, "normal");
            }
            break;
          case "chat_message":
            const updateMessages =
              message.targetChat === "group" ? setGroupMessages : setAiMessages;

            updateMessagesHelper(updateMessages, message, "text");

            if (message.solution) {
              const solutionMessage = {
                content: message.content,
                sender: message.sender,
                type: "agreed_solution",
                solution: message.solution,
              };
              updateMessages((prevMessages) => [
                ...prevMessages,
                solutionMessage,
              ]);
              setHighlightGroupChat(true);
              setTimeout(() => {
                setHighlightGroupChat(false);
              }, 6000);
            }
            break;
          case "multiple_choice":
            if (message.sender === "user") {
              handleStreamingMessage(
                {
                  ...message,
                  id: message.result.multipleChoiceId,
                  result: { ...message.result },
                },
                "multiple_choice"
              );
            } else {
              handleStreamingMessage(message, "multiple_choice");
            }
            break;
          case "solution_suggestion":
            handleStreamingMessage(message, "solution_suggestion");
            break;
          case "search_web":
            handleStreamingMessage(message, "search_web");
            break;
          case "youtube_video":
            handleStreamingMessage(message, "youtube_video");
            break;
          // case "offer_for_confirmation":
          //   handleStreamingMessage(message, "offer_for_confirmation");
          //   break;
          // Add cases for other message types as needed
          default:
            console.log(`Unhandled message type: ${message.type}`);
            break;
        }
      });
    });
    addSocketMessageHandler(socket, "goal updated", (newGoal) => {
      setGoal(newGoal);
    });
    addSocketMessageHandler(socket, "matchData", (data) => {
      setMatchData(data);
    });
    addSocketMessageHandler(socket, "finances", (data: Finances) => {
      setFinances(data);
    });
    addSocketMessageHandler(socket, "out_of_credits", (data) => {
      setOutOfCredits(true);
    });
    addSocketMessageHandler(socket, "brainstormQueueData", (data) => {
      setBrainstormQueueData(data);
    });
    addSocketMessageHandler(socket, "summary", (data) => {
      const { userId, summary } = data;
      setPreferenceSummaries((ps) => ({ ...ps, [userId]: summary }));
    });
    addSocketMessageHandler(socket, "error", handleError);
    addSocketMessageHandler(socket, "creator stats", handleCreatorStats);
    addSocketMessageHandler(socket, "offer_for_confirmation", (data) => {
      handleStreamingMessage(data, "offer_for_confirmation");
    });
    addSocketMessageHandler(socket, "multiple_choice", (data) => {
      handleStreamingMessage(data, "multiple_choice");
    });
    addSocketMessageHandler(socket, "thinking...", () => {
      setThinking(true);
      socket.once("messages", () => setThinking(false));
      socket.once("chat_message", () => setThinking(false));
      socket.once("multiple_choice", () => setThinking(false));
      socket.once("solution_suggestion", () => setThinking(false));
      socket.once("error", () => setThinking(false));
    });
    addSocketMessageHandler(socket, "highlight_solutions_sidebar", () => {
      setHighlightSolutionsSidebar(true);
      setTimeout(() => {
        setHighlightSolutionsSidebar(false);
      }, 5000);
    });

    // const countMessage = (message: ClientMessage) => {
    //   console.log(message);
    //   return message.targetChat !== chatId || ;
    // };

    addSocketMessageHandler(socket, "chat_message", (data) => {
      const updateMessages =
        data.targetChat === "group" ? setGroupMessages : setAiMessages;

      updateMessages((prevMessages) => {
        let messageExists = false;
        let shouldCount = false;
        const newMessages = prevMessages.map((message) => {
          if (message.id && message.id === data.id) {
            messageExists = true;

            const newMessage = { ...message, content: data.content };
            return newMessage;
          } else {
            shouldCount = data.targetChat !== chatId;
          }
          return message;
        });
        if (!messageExists && data.id) {
          newMessages.push({
            sender: data.sender,
            type: "text",
            id: data.id,
            content: data.content,
            userId: data.userId,
          });

          if (shouldCount) {
            setUnread((prev) => ({
              ...prev,
              [data.targetChat]: prev[data.targetChat] + 1,
            }));
          }
        }

        return newMessages;
      });

      if (data.solution) {
        const solutionMessage = {
          content: data.content,
          sender: data.sender,
          type: "agreed_solution",
          solution: data.solution,
        };
        updateMessages((prevMessages) => [...prevMessages, solutionMessage]);
        setHighlightGroupChat(true);
        setTimeout(() => {
          setHighlightGroupChat(false);
        }, 6000);
      }
    });
    addSocketMessageHandler(socket, "top solutions", handleTopSolutions);
    addSocketMessageHandler(
      socket,
      "updateSolution",
      ({ solutionId, newProperties }: UpdateSolutionMessage) => {
        setSolutions((prevSolutions) =>
          prevSolutions.map((s) => {
            if (s.id === solutionId) {
              return { ...s, ...newProperties }; // Merge properties
            }
            return s;
          })
        );
      }
    );
    addSocketMessageHandler(
      socket,
      "addSolutions",
      ({ solutions }: AddSolutionsMessage) => {
        setSolutions((prevSolutions) => [...prevSolutions, ...solutions]);
      }
    );
    addSocketMessageHandler(
      socket,
      "rankings",
      ({ rankings }: RankingsMessage) => {
        setRankings(rankings);
      }
    );

    addSocketMessageHandler(
      socket,
      "updateRankings",
      ({ rankingsDiff }: UpdateRankingsMessage) => {
        setRankings((prevRankings) => {
          const newRankings = { ...prevRankings, ...rankingsDiff };
          return newRankings;
        });
      }
    );

    addSocketMessageHandler(socket, "everyone_fine_solution", (data) => {
      setRainConfetti(true);
    });

    // Clean up the event listeners on component unmount
    return () => {
      socket.removeAllListeners();
    };
  }, [setHighlightGroupChat, handleDisconnect]);

  const handleUsernameSubmit = (usernameInput, emailInput) => {
    const sanitizedUsername = usernameInput.replace(/[^a-zA-Z0-9]/g, "");
    const userId = sanitizedUsername + Date.now();

    localStorage.setItem("userId", userId);
    localStorage.setItem("username", sanitizedUsername);

    setUserId(userId);
    setUsername(sanitizedUsername);
    setEmail(emailInput);
  };

  useEffect(() => {
    socket.io.on("reconnect", () => {
      if (userId && username && roomId) {
        console.log("Reconnected to the server");
        socket.emit("rejoin room", { userId, username, roomId });
      }
    });

    if (userId && username && roomId) {
      register({ userId, username, roomId, email });
      joinRoom({ userId, username, roomId, email });
    }

    return () => {
      socket.io.off("reconnect");
    };
  }, [userId, username, roomId, email]);

  // Define the value object for the context
  const socketContextValue: SocketContextType = {
    roomId,
    setRoomId,
    aiMessages,
    groupMessages,
    goal,
    userRoomMetadatas,
    users,
    errors,
    creatorsWithStats,
    thinking,
    highlightSolutionsSidebar,
    phase,
    sortAnimationSpeed,
    joiningInProgress,
    preferenceSummaries,
    matchData,
    brainstormQueueData,
    finances,
    outOfCredits,
    handleCreateRoom,
    doneVoting,
    isConnected,
    checkIsConnected,
    reconnect,
    handleUsernameSubmit,
    sendChatMessage,
    handleGoalChange,
    sendVerdictReaction,
    setVote,
    setVerdict,
    sendStartMessage,
    justSolveIt,
    sendMultipleChoiceReaction,
    emitTypingEvent,
    setPreferenceSummaries,
    rainConfetti,
    setRainConfetti,
    chatId,
    setChatId,
    unread,
    setUnread,
    generateAiText,
  };

  const currentUser: CurrentUserContextType = useMemo(
    () => ({
      userId,
      username,
      isAdmin,
    }),
    [userId, username, isAdmin]
  );

  const solutionsContextValue: SolutionsContextType = {
    solutions,
    ratings,
    setSolutions,
    setRatings,
    sendSolutionReaction,
    sendSolutionReactionInLatestCard,
    newSidebarSolutions,
  };

  return (
    <CurrentUserContext.Provider value={currentUser}>
      <SocketContext.Provider value={socketContextValue}>
        <SolutionsContext.Provider value={solutionsContextValue}>
          {children}
        </SolutionsContext.Provider>
      </SocketContext.Provider>
    </CurrentUserContext.Provider>
  );
};

// Custom hook to access the socket service context
export const useSocket = () => {
  return useContext(SocketContext);
};

export const useCurrentUser = () => {
  return useContext(CurrentUserContext);
};

export const useSolutions = () => {
  return useContext(SolutionsContext);
};

type LocalStateContextType = {
  isNerdModeOn: boolean;
  highlightGroupChat: boolean;
  leftSidebarOpen: boolean;
  setLeftSidebarOpen?: Dispatch<SetStateAction<boolean>>; // TODO any
  setHighlightGroupChat?: Dispatch<SetStateAction<boolean>>;
  toggleNerdMode?: () => void;
  filterSolutions?: string[];
  setFilterSolutions?: Dispatch<SetStateAction<string[]>>;
};

export const LocalStateContext = createContext<LocalStateContextType>({
  isNerdModeOn: false,
  highlightGroupChat: false,
  leftSidebarOpen: false,
  filterSolutions: [sketch_name],
}); // TODO: better default value?

export const LocalStateProvider = ({ children }) => {
  const [isNerdModeOn, setIsNerdModeOn] = useState(false);
  const [filterSolutions, setFilterSolutions] = useState([
    sketch_name,
    imagined_option_name,
    "unrated",
    "low_rated",
  ]);
  const [highlightGroupChat, setHighlightGroupChat] = useState(false);
  const [leftSidebarOpen, setLeftSidebarOpen] = useState(false);

  // Define functions to update the state
  const toggleNerdMode = () => setIsNerdModeOn((prev) => !prev);
  // Define the context value object
  const contextValue = {
    isNerdModeOn,
    highlightGroupChat,
    leftSidebarOpen,
    setLeftSidebarOpen,
    setHighlightGroupChat,
    toggleNerdMode,
    filterSolutions,
    setFilterSolutions,
  };

  return (
    <LocalStateContext.Provider value={contextValue}>
      {children}
    </LocalStateContext.Provider>
  );
};

export const useAppState = () => {
  return useContext(LocalStateContext);
};

export function debounce(func, wait, immediate) {
  let timeout;
  return function () {
    const context = this;
    const args = arguments;
    const later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) {
      func.apply(context, args);
    }
  };
}

function addSocketMessageHandler<T extends ServerToClientApiMessageType>(
  socket: any,
  clientApiMessageType: T,
  fn: (obj: ServerToClientApiMessageObjTypes[T]) => void
) {
  socket.on(clientApiMessageType, fn);
}
